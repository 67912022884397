

.upload_data_wrpr{
  width: 100%;
  /* margin-top: -145px; */
}
.upload_data_wrpr .MuiFab-root{
  background: #e0bbaf!important;
  background: linear-gradient(180deg,rgba(224,187,175,1) 0%,rgba(213,170,156,1) 50%,rgba(206,155,139,1) 50%,rgba(192,135,117,1) 100%)!important;
  width: 47px;
    height: 47px;
}
.upload_data_wrpr #list h1{
  font-size: 29px;
  background: rgb(87,53,117) !important;
  background: linear-gradient(90deg, rgb(255 112 69) 0%, rgb(251 50 5) 100%) !important;
  border-radius: 8px;
  padding: 5px 14px;
  color: #ffffff;
}
.blog_img_wrpr{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}
#list{
  margin-right: 10px;
}
.image_container{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
/* .image_container div{
  width: 32.5% !important;
} */
.image_container .image_preview{    
  width: 100%;
  height: 300px;
  display: block;
}
/* .box{
  height: 500px !important;
  display: flex;
} */
.content_wrpr {
  margin: 0 10px;
  width: 20%;
  background: #ddd;
  padding: 10px;
  border: 1px solid #c4c4c4;
}
.content_wrpr .cropper-container {width: 100% !important;}
.cropimagebtn {
  background: #e95e14 !important;
  display: block !important;
  margin: 0 auto !important;
  margin-top: 10px !important;
  color: #fff !important;
  border: 1px solid #fb5f0c !important;
  box-shadow: 5px 4px 6px #b9470a57 !important;
}

.btn_container .css-sghohy-MuiButtonBase-root-MuiButton-root {background: #e0bbaf!important;
  background: linear-gradient(180deg,rgba(224,187,175,1) 0%,rgba(213,170,156,1) 50%,rgba(206,155,139,1) 50%,rgba(192,135,117,1) 100%)!important;}

  .box {
      margin: 0 10px;
      width: 20%;
      background: #ddd;
      padding: 10px;
      border: 1px solid #c4c4c4;
  }

.image_name {
      display: block;
  }

  .btn_container {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 20px 0 0;
  }
  .image_name {
      display: block;
      background: #e95e14;
      padding: 10px;
      margin-top: 10px;
      color: #fff;
  }


  .cropimagewrapper {
      max-height: 382px;
      overflow: hidden;
  }

  /* .link_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */

  .link_input_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
  }

  .link_save_btn{
    margin-left: 10px !important;
  }

  .link_thumbnail_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }


  .each_link_container{
    display: flex;
    position: relative;
  }


  .link_thumbnail {
    height: 200px;
    border-radius: 5px;
    border: 8px solid grey;
    margin: 0 10px;
  }

  .link_thumbnail_container{
    height: 100%;
  }

  .linkid_remove{
    position: absolute;
    margin: auto;
    right: 10px;
    background-color: #fff;
    opacity: 0.8;
    border-radius: 15%;
  }
  /* .linkid_remove button{
    position: absolute;
    left: -35px;
  } */
  .linkid_play{
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    background-color: #fff;
    opacity: 0.6;
    border-radius: 50%;
  }

  .linkid_remove button{
   padding: 0 0;
   
  }

  .linkid_remove button svg{
    width: 20px;
    height: 20px;
  }

  .linkid_play button{
    padding: 0 0;
    
   }
 
   .linkid_play button svg{
     width: 40px;
     height: 40px;
   }

   .dialog_container{
    padding: 2px !important;
    position: relative;
   }

   .dialog_close_btn{
    position: absolute !important;
    top: 6px;
    right: 6px;
    background-color: #fff !important;
    padding: 3px !important;
   }
.uploadbttn_wrpr{
  display: flex;
  justify-content: center;
  padding: 10px 34px;
}
.uploadbttn_wrpr .MuiButton-root {
  padding: 10px 34px;
  background: #e0bbaf!important;
  background: linear-gradient(180deg,rgba(224,187,175,1) 0%,rgba(213,170,156,1) 50%,rgba(206,155,139,1) 50%,rgba(192,135,117,1) 100%)!important;
}
.dltallbtn_wrpr{
  display: flex;
  justify-content: center;
}
.dltallbtn_wrpr .MuiButton-root{
  padding: 10px 34px;
  background: #e0bbaf!important;
  background: linear-gradient(180deg,rgba(224,187,175,1) 0%,rgba(213,170,156,1) 50%,rgba(206,155,139,1) 50%,rgba(192,135,117,1) 100%)!important;
}
.cropper-crop {
  height: 300px  !important;
}

.uploadAll, .deleteAll{
  display: block !important; 
  margin: 0 auto !important;
  background: #e0bbaf!important;
    background: linear-gradient(180deg,rgba(224,187,175,1) 0%,rgba(213,170,156,1) 50%,rgba(206,155,139,1) 50%,rgba(192,135,117,1) 100%)!important;
}
.deleteAll{
  margin-bottom: 10px !important;
}
.sun-editor .se-toolbar.se-toolbar-sticky{
  position: relative !important;
}
.sun-editor .se-toolbar-sticky-dummy{
  height: auto !important;
}

/* ========================================EDIT IMAGE================================== */
@media (max-width: 1199px){
.upload_data_wrpr .box, .upload_data_wrpr .content_wrpr{width: 250px;max-height: 350px;margin: 10px 5px;}
.content_wrpr .cropper-container {
  height: 305px !important;
}
}
/* @media (max-width: 1199px){
  .upload_data_wrpr .box, .upload_data_wrpr .content_wrpr{width: 250px;}
} */
@media (max-width: 600.98px){
  .upload_data_wrpr .content_wrpr .cropper-crop {
    height: 261px !important;
  }
}


   /* ------------------------- Chip Text Box ------------------------------ */
   .chip_textbox_container{
    display: flex;
    flex-direction: column;
    align-items: center;
   }
   .chip_textbox_input_container{
    display: flex;
    justify-content: center;
    align-items: center;
   }
   .chips_container{
    margin-top: 10px;
   }

   .drawer_chip_list{
    display: flex;
   }
   .drawer_chip_item{
    margin: 0 20px;
   }



   @media (max-width: 1600px){
    .link_input_container{
      width: 50%;
    }
   }
   @media (max-width: 998.98px){
  .link_input_container {
    width: 100%;
}
   }
   @media (max-width: 600px){
    .link_thumbnail{
      height: 150px;
    }
    .link_input_container{
      flex-direction: column;
    }
    .linkid_enter{
      margin-bottom: 10px !important;
    }
    .link_save_btn{
      margin-top: 10px !important;
    }
   }
   @media (max-width: 450px){
    .link_thumbnail{
      height: 90px;
    }
   }