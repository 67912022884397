body{margin:0;font-family: 'Mulish', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
h1,h2,h3,h4,h5,h6,p{padding:0;margin:0}
code{font-family:source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace}
/* .MuiButton-text{font-weight:600!important;border-radius:8px!important;background:#e0bbaf!important;background:linear-gradient(180deg,rgba(224,187,175,1) 0%,rgba(213,170,156,1) 50%,rgba(206,155,139,1) 50%,rgba(192,135,117,1) 100%)!important;cursor:pointer!important;text-transform:none!important} */
.MuiButton-root{line-height:unset!important;letter-spacing:unset!important}
.MuiTouchRipple-root{display:none}

/* <----------------------------------backend pages css start---------------------------------------------------------->>> */


/* <----------------------------Login_page Css Start-----------------------------> */

.commonsection_backend{
    width: 1760px;
    margin: 0 auto;
}

 
@media (max-width: 1780px) {
    .commonsection_backend{width:90%!important}
    }


.login_mainwrapper{
    background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Backend_asset/login_page/login_page_bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    overflow: hidden;
    position: relative;

}
.login_subwrpr{
    padding-top: 25px;
    margin-bottom: 100px;

}

.login_mainwrapper .login_text h1 span{
    font-size:73px;
    line-height:99px;
    text-transform:uppercase;
    background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 55%, rgba(207,207,207,1) 55%, rgba(207,207,207,1) 100%);
    -webkit-text-fill-color:transparent;
    -webkit-background-clip:text;
    display:block;
    background-size:100% 100%;
    background-repeat:no-repeat;
    margin-bottom: 15px;
    font-weight: bolder;
}
.login_mainwrapper .login_text{
    text-align: center;

}
.login_mainwrapper .login_text h3{
    font-size: 26px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 30px;
}
.login_mainwrapper .loginform_section{
    width: 692px;
    background: rgb(51,27,38);
background: linear-gradient(180deg, rgba(51,27,38,1) 0%, rgba(40,22,33,1) 50%, rgba(29,16,27,1) 100%);
margin: 0 auto;
text-align: center;
border-radius: 10px;
}
.login_form_text{
    margin: 11px;
    padding-top: 11px;

}
.login_form_title{
    background: rgb(233,92,21);
background: linear-gradient(180deg, rgba(233,92,21,1) 0%, rgba(231,39,35,1) 100%);
border-radius: 10px 10px 0 0;
padding: 28px;
}
.login_form_title h2{
    text-transform: uppercase;
    color: #ffffff;
    font-size: 36px;
    font-weight: bold;

}

.main_form_wrpr{
    padding: 30px;
}
.login_mainwrapper .formInline .email{
    margin-bottom: 30px;
}
.login_mainwrapper .formInline .password{
    margin-bottom: 58px;
}
.login_mainwrapper  label {
    color: #ffffff;
    font-size: 21px;
    /* font-weight: 700; */
    font-family: "Mulish", sans-serif;
    margin-bottom: 10px;
}
.loginFormInputBox{
    /* border-bottom: 1px solid white;
    padding-bottom: 13px; */
    display: flex;
    justify-content: left;
    flex-direction: column;
    text-align: left;
}
.loginFormInputBox:nth-child(2){
    /* margin: 70px 0 60px; */
    padding: 20px 0;
}
.loginFormInputBox input{
    border: none;
    width: 100%;
}
.login_mainwrapper .loginFormTextInput{
    /* border: none;
    border-radius: 0;
    background: none; */
    background: #ffffff26;
    height: 50px;
    border: 1px solid #ffffff21;
    border-radius: 3px;
    font-size: 21px; 
    padding: 0 0 0 10px; 
    color: #fff;
    width: 98%;
}

.MuiFormControl-root{
    width: 100%;
}
.login_mainwrapper .buttonLists .MuiButton-label {
    font-size: 25px!important;
    text-transform: uppercase;
    background: rgb(62,35,45);
background: linear-gradient(180deg, rgba(62,35,45,1) 0%, rgba(62,35,45,0.9192051820728291) 53%, rgba(24,16,18,0.9304096638655462) 53%, rgba(24,16,18,1) 100%);
-webkit-text-fill-color:transparent;
    -webkit-background-clip:text;
    display:block;
    background-size:100% 100%;
    background-repeat:no-repeat;
    padding: 8px 47px;
}
/* .MuiSvgIcon-root{
.login_mainwrapper .buttonLists{
    margin-bottom: 28px;
}
.login_mainwrapper .MuiSvgIcon-root{
    display: none !important;
} */

.form_footer_section{
    background-color: #150a15;
    padding: 25px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    /* border-radius: 10px;
    margin: 60px auto 30px; */
}
.form_footer_text h3{
font-size: 16px;
color: #787678;
text-align: center;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.login_mainwrapper .formdiv .MuiOutlinedInput-root{
    font-size: 21px;
    color: #ffffff;
}
.login_mainwrapper .password .MuiIconButton-root{
    color: #ffffff;
}

.linkText {
    color: #ffffff;
    font-size: 16px;
    text-decoration: none;
    padding: 30px auto 24px;
}
input:-internal-autofill-selected{
    background-color: #000 !important;
}


/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-box-shadow: 0 0 0px 1000px rgb(40 22 33) inset;
  transition: background-color 5000s ease-in-out 0s;
  font-size: 21px !important;
}
input{outline: none !important;}















 
/* --------------------------------- Login_page Responsive Start ---------------------------- */

@media (max-width: 1199px) {
.login_mainwrapper  .loginform_section{
    min-width: 100%;
    width: 100%;
}
}

@media (max-width: 800px) {
    .login_mainwrapper .login_text h1 span{
        font-size: 50px;
        line-height: 60px;
        background: rgb(255,255,255);
        -webkit-background-clip: text;
    }
}
@media (max-width: 400px) {
.login_mainwrapper .buttonLists{
    width: 100%;
}
}
/* --------------------------------- Login_page Responsive End ---------------------------- */

/* --------------------------------- Login_page Css End ---------------------------- */


/* --------------------------------- Forget Pasword_page Css Start ---------------------------- */

.submitbtnsection .MuiButton-root {
    font-size: 22px!important;
    color: #110c0b!important;
    padding: 17px 47px;
    margin-bottom: 8px;
}
.forget_mainwrapper .formCls .formInline{
    display: block;
}
.forget_mainwrapper .login_mainwrapper label{
    font-size: 21px;
    font-weight: 700;
    }
    .forget_mainwrapper .login_mainwrapper input{
        background: #ffffff26;
        height: 50px;
        border: 1px solid #ffffff21;
        border-radius: 3px;
        font-size: 21px;
        padding: 0 5px;
        font-family: "mulish";
        color: #ffffff;
    }
   .forget_mainwrapper .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
    top: -10px;
   }
   .forget_mainwrapper .MuiInputLabel-root.Mui-focused{
       color: #ffffff;
   }
   .forget_mainwrapper .main_form_wrpr h3 {
    text-align: left;
   }


@media (max-width: 1199px) {
.forget_mainwrapper .login_mainwrapper .formInline .email{
    margin-bottom: 40px;
}
}
@media (max-width: 500px) {
    .forget_mainwrapper .login_mainwrapper .submitbtnsection .MuiButton-root{
        width: 100%;
    }
    }
@media (max-width: 400px) {
.forget_mainwrapper .login_mainwrapper .submitbtnsection .MuiButton-root span{
    font-size: 20px!important;
}
}

/* --------------------------------- Forget Pasword_page Css End ---------------------------- */



.admindash_wrapper{
    /* background: rgb(25,16,33);
background: linear-gradient(90deg, rgba(25,16,33,1) 0%, rgba(36,25,41,1) 50%, rgba(48,52,52,1) 100%);
    height: 100%; */
    /* min-height: 100vh; */
    padding-top: 30px;
    overflow: hidden;
}
.adminheader_subwrpr{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    background: #e72323;
    background: linear-gradient(90deg,rgba(231,35,35,1) 0%,rgba(233,95,19,1) 100%);
    border-radius: 8px;
    padding: 20px 35px;
    z-index: 9;
    position: relative;
    margin-bottom: 20px;
}
.topMenuListsWrapper{
    display: flex;
}
.topMenuListsWrapper .active, .topMenuListsWrapper button:hover{
    background-color: #700c0c;
}
.admindash_wrapper .MuiButton-text {
    background: #700c0c !important;
    color: #ffffff !important;
}
.admindash_wrapper .MuiButtonBase-root-MuiButton-root{
    font-size: 18px;
    color: white;
    font-family: "mulish";     margin: 0 2px;
}

.topRightMenu{
    display: flex;
}
.topRightMenu .MuiButton-text{
    background: #faf9f92e !important;
    border-radius: 15px !important;
    margin: 0 5px;
}

@media (max-width: 991px) {
    .adminheader_subwrpr{
    flex-direction: column;
    padding: 15px;
}
.admindash_wrapper .MuiButton-text{margin-bottom: 10px;}
}



/* -------------------------------- Dashboard start -------------------------------- */

.dashboard_mainwrapper .MuiCard-root{
    border-radius: 35px;
    background-color: #e5dede;
}
.dashboard_mainwrapper .MuiTypography-root{
    font-family: 'Mulish', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    font-size: 25px;
font-weight: bold;
margin-bottom: 0 !important;
}
.dashboard_mainwrapper .MuiCardContent-root{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 12px 0px !important;
    flex-wrap: wrap;
    /* padding: 12px !important; */
}
.loggedUser .formCls .formInline .MuiIcon-root{
    align-items: center;
    border: 2px solid #ff4040;
    border-radius: 100px;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: 12px;
    padding: 2px;
    width: 40px;
}

.listtable_blog_wrapper{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}
@media (max-width: 400px){
.listtable_blog_wrapper{
    display: block;
}
}
.logged_wrpr{
    /* justify-content: unset !important; */
}
 
/* -------------------------------- Dashboard end -------------------------------- */


/* ---------my account section- start--------- */

.myacdashboard  div{ text-align: left;
    /* box-shadow: none !important; */
}

.myacdashboard  .blockInnerWrapper h1{ text-align: center !important;
}

.myacdashboard .myacformheader_wrapper{
    text-align: center;
}
.myacdashboard .myaccbtn{background-color: #c9d5df;color: #000;font-size: 22px; }
.myacdashboard  div .MuiButtonBase-root{margin: 0px .5% 15px;}
/* .myacdashboard  div .MuiButtonBase-root{margin: 0px .5% 0px;} */
.blockInnerWrapper .passwordUpdateForm .MuiInputAdornment-root .MuiButtonBase-root {margin: 0px .5% 0px !important;}
.myacdashboard .submitbuttonmyacc{ 
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 0px;
}
.myacdashboard .submitbuttonmyacc .submit{
    margin: 0px 2px;
}
.userUpdateForm{
    padding-bottom: 0;
}
/* .myacdashboard .blockInnerWrapper{    margin: 0 50px;} */
.myacdashboard div .MuiButtonBase-root:hover,.myacdashboard div .MuiButtonBase-root.flag{
    /*  */
    background-color:#e95e14;
    color: #fff;
    font-size: 22px;
}

@media screen and (max-width: 991px){
    .myacdashboard .submitbuttonmyacc{
        justify-content: center;
    }
}

/* ---------my account section- end--------- */



/* <<<<<<<-----------------------update-user details(myaccount) css start--------------------------->>>>>>>>> */
.blockInnerWrapper{
    background: #c9d5df;
    background: linear-gradient(180deg,#dfe8ed,#c9d5df);
    border-radius: 15px;
    flex: 1 0 auto;
    padding: 20px;
}
.blockInnerWrapper .reactTableWrapper{
    border-color: #dee2e6;
    color: #212529;
    margin-bottom: 0;
    vertical-align: top;
    width: 100%;    
}
.blockInnerWrapper .formCls{
    background: #c9d5df;
    background: linear-gradient(180deg,#dfe8ed,#c9d5df);
    border-radius: 15px;
    box-shadow: 0 0 5px #854e05!important;
    margin: 0;
    padding: 10px 20px;
    /* width: 100%; */

}
.blockInnerWrapper .formInline{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.blockInnerWrapper .formGroup{
    width: 49.5%;
}
.blockInnerWrapper .formGroup label{
    text-align: left !important;
    padding: 10px 0px;
}
.blockInnerWrapper .MuiInputLabel-root {
    color: rgb(18 18 18 / 60%);
font-weight: bold;
}
.myacdashboard .submitbuttonmyacc .MuiButton-text{
    font-size: 22px!important;
    color: #ffffff!important;
    padding: 15px 45px;
    background: rgb(87,53,117) !important;
background: linear-gradient(90deg, rgba(87,53,117,1) 0%, rgba(139,34,11,1) 100%) !important;
margin-top: 15px;
box-shadow: 6px 5px 7px #8d616c;
}
/* .blockInnerWrapper .submitbuttonmyacc .MuiSvgIcon-root {
display: none !important;
} */
.blockInnerWrapper .formInline .Address textarea{
    height: 34px !important;
    padding: 10px;
    resize: none;
    border-radius: 4px;
    background: transparent;
    /* border: 1px solid grey; */
    border-color: rgba(0, 0, 0, 0.23);
}
.blockInnerWrapper .Address{
    width: 100% !important;
}
.blockInnerWrapper .State, .blockInnerWrapper .city, .blockInnerWrapper .Zip{
    width: 32.5% !important;
}

.blockInnerWrapper h1{
    padding-bottom: 20px;
}

.css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon{ opacity: inherit!important;}

.pageMain .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon svg{ font-size: 20px!important;}

 /* .blogTable .MuiTableCell-head:nth-of-type(4){ width: 50%;} */



@media (max-width: 991px) {
    .blockInnerWrapper .formGroup {
        width: 100%;
    }

    .blockInnerWrapper .State, .blockInnerWrapper .city, .blockInnerWrapper .Zip{
        width: 100% !important;
    }
}
/* <<<<<<<-----------------------update-user details(myaccount) css start--------------------------->>>>>>>>> */

.blockInnerWrapper .reactTableWrapper_reset {
    border-color: #dee2e6;
    color: #212529;
    margin-bottom: 0;
    vertical-align: top;
    width: 100%;
}

.blockInnerWrapper .reactTableWrapper_reset .formInline .formGroup{
    width: 32.33%;
}

@media (max-width: 991px) {
.blockInnerWrapper .reactTableWrapper_reset .formInline .formGroup {
    width: 100%;
}
}
.circularProgress{
    display: flex;
    justify-content: center;
}

/*--------------listing page---------------------*/

.buttonLists button{ margin-bottom: 10px!important;}

/* .listtableWrapper{ width: 92%; margin: 0 auto;} */

.listtableWrapper .buttonListCls{align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px!important;
    margin-top: 0!important;
    margin-left: auto;
    /* position: absolute;
    top: 10px;
    right: 100px; */
}

    .listtableWrapper .buttonListCls button {
        /* color: #2a2727; */
        font-size: 18px;
        padding: 8px 20px;
        background: rgb(87,53,117) !important;
        background: linear-gradient(90deg, rgba(87,53,117,1) 0%, rgba(139,34,11,1) 100%) !important;
        /* margin-top: 15px; */
        color: #ffffff;
    }  


    .listtable_blog {
        
        color: #212529;
        margin-bottom: 26px;
        vertical-align: top; 
        border: solid 15px #564f4c;
        border-radius: 10px; background: #ccc;
        width: 100%;
        /* min-height: 467px; */
        
    }

    /* .listtable_blog_wrapper .listtable_blog:nth-child(1){width: 100%%; height: 100%;position: relative;}
    .listtable_blog_wrapper .listtable_blog:nth-child(2){width: 45.5%;min-height: 100%;} */
    /* .listtable_blog_wrapper .listtable_blog .buttonListCls:nth-child(2){
        display: none;
    } */

    .listtable_blog .css-1mxz8qt-MuiPaper-root{ margin: 0!important; border-radius: 0px;}

    /* .listtableWrapper th:nth-child(6){
       text-align: center;
    } */
    .listtableWrapper th:nth-child(4){
        display: flex;
        border: none;
        /* justify-content: center; */
    }
    /* .listtableWrapper .blogTable th:nth-child(6){
        text-align: center !important;
    } */

    .listtableWrapper .reactTableWrapper .MuiTypography-h6, .listtableWrapper .reactTableWrapper .MuiTypography-subtitle1 {
        color: #000!important;
        font-size: 20px;
    }

    .listtableWrapper .css-pjjt8i-MuiToolbar-root {
        min-height: inherit;
    }

    .listtableWrapper thead tr{
        background: rgb(204,203,191);
background: linear-gradient(0deg, rgba(204,203,191,1) 0%, rgba(153,153,152,1) 100%);
        border: 1px solid #fff;
        border-radius: 100px;
        border-radius: 4px;
        box-shadow: inset 0 0 1px #fff, 0 -2px 8px #fff, 0 2px 5px rgb(0 0 0 / 10%), 0 8px 10px rgb(0 0 0 / 10%);
        color: #081023;
        cursor: pointer;
        font-size: 16px;
      
        padding: 5px 30px;
        text-shadow: 0 0 5px rgb(0 0 0 / 20%);
        -webkit-transition: box-shadow .5s;
        vertical-align: bottom;
    }

    .listtableWrapper tbody{color:#081023;background:#c9d5df;background:linear-gradient(180deg,#dfe8ed 0,#c9d5df 100%);cursor:pointer; font-size:16px;padding:5px 30px;border-radius:100px;box-shadow:0 0 1px white inset,0px -2px 8px white,0 2px 5px rgb(0 0 0 / 10%),0 8px 10px rgb(0 0 0 / 10%);-webkit-transition:box-shadow .5s;border:1px solid #fff;border-radius:4px}
    .listtableWrapper thead tr,.reactTable thead tr td{font-weight:500}
    .listtableWrapper th{display:table-cell;vertical-align:baseline;font-weight:bold;text-align:-internal-center;text-align:inherit;text-align:left;font-size:16px;padding:10px;text-shadow:none}
    .listtableWrapper>tbody{vertical-align:inherit}
    .listtableWrapper tr{display:table-row;vertical-align:inherit;border-color:inherit;position: relative; background-color:#d5d7d9}
    .listtableWrapper>:not(caption)>*>*{
        padding:0;
        /* border-bottom-width:0; */
        min-height:inherit}
    .listtableWrapper .MuiTypography-h6{font-size:20px;color:#000!important}
      .listtableWrapper .MuiTypography-subtitle1{font-size:20px;color:#000!important}
    .listtableWrapper .MuiToolbar-root .css-i4bv87-MuiSvgIcon-root{color:#000; font-size: 30px;}
    .listtableWrapper td{display:table-cell;vertical-align:inherit;border-bottom:1px solid #b8c4d3!important;padding:5px 10px;font-size:16px;word-break:break-word;white-space:normal;text-align:left; }
    
    .listtableWrapper tr:last-child td{border-bottom:0!important}
    .listtableWrapper tr:nth-child(even){background-color:#e1e3e5}
    .listtableWrapper .css-i4bv87-MuiSvgIcon-root{width: 20px;
        height: 20px}
    .listtableWrapper .MuiToolbar-root {
        background: #b3b3b3; 
        padding: 10px;     min-height: inherit;
    }
    .listtableWrapper .MuiTablePagination-root{border-radius:0}
    .listtableWrapper .MuiTablePagination-root *{border-radius:0;font-size:14px}
    .listtableWrapper td button{color:#e54e06}

    .listtableWrapper .MuiTablePagination-root .MuiInputBase-colorPrimary svg{    width: 20px;
        height: 20px;
        top: 3px;}

        .listtableWrapper td img{
            width: 100px;
        }

        .blogTable .listtableWrapper th:nth-child(6){
            text-align: center;
        }


        .listtableWrapper .blogTable td:nth-child(6){
        padding: 5px 0px;
        }

        .listtableWrapper .contactTable td:nth-child(6){
        padding: 5px 0px;
        }

        /* .listtableWrapper td:nth-child(2) {
            width: 5%;
        } */
.searchBarCloseIcon button{
    background: linear-gradient(180deg,#dfe8ed,#c9d5df);
    border: 1px solid #fff!important;
    border-radius: 1px 0 0 28px!important;
    box-shadow: 0 0 15px rgb(0 0 0 / 30%);
    color: #444!important;
    margin: 5px!important;
    /* padding: 0 0 3px -38px!important; */
    /* right: 0px; */
    top: 3px;
    position: absolute;
    right: 4px;
        }

        .pageMain {
            min-height: 80vh;
        }
        

        .searchBarHead .searchForm .css-1nrlq1o-MuiFormControl-root{margin: 0 20px!important}


        .custommodal{width:100%;display:flex;align-items:center;justify-content:center;height:100vh}
        .custommodal .MuiBox-root{background:#c9d5df;background:linear-gradient(180deg,#dfe8ed 0,#c9d5df 100%);padding:10px;border-radius:15px;border:solid 5px #fff;width:auto;min-width:600px;max-width:90%;position:relative;top:0!important;left:0!important;text-align:left!important;transform:inherit!important;max-height:68%;display:flex;flex-direction:column}
        .modalview_list{overflow:hidden;max-height:500px;overflow-y:auto}
        .modalview_list::-webkit-scrollbar{width:5px;background:#fff;opacity:1;outline:1px solid #a3a09f;border-radius:10px}
        .modalview_list::-webkit-scrollbar-track{box-shadow:inset 0 0 6px rgba(0,0,0,.1)}
        .modalview_list::-webkit-scrollbar-thumb{background-color:#e1480a;outline:1px solid #cb3109;border-radius:10px;overflow-y:hidden;overflow-x:hidden}
        .managerModal .MuiBox-root{overflow-y:auto;max-height:68%}
        .CloseModal{position:absolute!important;right:1px;top:1px;background-color:#fff!important;padding:0!important;color:#000!important}
        .CloseModal svg{width:28px;height:28px}
        .modalview_group{padding:5px;border:solid 1px #b8c4d3}
        .modalview_group label{font-size:18px;
            width: 50%;}
        .modalview_group label:first-of-type{color:#e54e06;padding-right:10px;font-weight: bold;width: 23%;
        }
        .modalview_group:nth-of-type(odd) {
            background: #b8c4d3;
        }
        .modalview_list .Image{
            display: flex;
        }

        .modalview_list .modalview_group div {
            display: flex;
            padding: 8px;
        }

        /* .modalview_group label:nth-child(1){font-size:18px;
            width: auto;} */

            /* .modalview_list .modalview_group div:nth-child(1) {display: block;} */
        
        /* .MuiModal-root .MuiBox-root {
            background: #c9d5df;
            background: linear-gradient(180deg, #dfe8ed 0, #c9d5df 100%);
            padding: 10px;
            border-radius: 15px;
            border: solid 5px #fff;
            min-width: 320px;
            max-width: 90%;
            position: relative;
        } */
        

        .modal2 .MuiBox-root{
            width: 460px !important;
            min-width: 460px !important;
        }

        .MuiModal-root  .MuiBox-root h2 {
            font-size: 24px;
            font-weight: normal;
            margin: 0;
            padding: 0 0 10px 0;
        }
        
        .MuiModal-root  .MuiBox-root button {
            color: #000;
          
            /* margin: 5px; */
        }
        
        .modalCloseBtn {
            position: absolute!important;
            right: 1px;
            top: 1px;
            background-color: #fff!important;
            padding: 0!important;
            color: #000!important;
            margin: 0!important;
            background: #fff!important;
        }
        
        .modalCloseBtn svg {
            width: 28px;
            height: 28px;
        }
        
        .custommodal h1 {
            color: #2a2716;
            font-size: 25px;
            margin: 0;
            padding: 0 0 8px 0;
            text-align: center;
          
        }

  
        
        .DrawerBox h2 {
            background-color: #e95e14;
            color: #fff;
            font-size: 22px; padding: 15px;
            text-align: center;
        }
        
        .closeDrawer {
            background: linear-gradient(180deg, #dfe8ed 0, #c9d5df 100%);
            margin: 0px!important;
            padding: 0px!important;
            border: solid 1px #fff!important;
            border-radius: 1px 0 0 28px !important;
            right: -5px;
            top: -5px;
            padding-left: 10px !important;
            padding-bottom: 3px !important;
            box-shadow: 0 0 15px rgb(0 0 0 / 30%);
            color: #444 !important;     position: absolute !important;
            right: 0;
            top: 0;
        }

        .custommodal  .MuiBox-root {width: 460px; 
            max-height: inherit;
        }



        .GridBlogWrapper { margin: 15px 0;}
        .GridBlogWrapper .listtableWrapper{ width: 100%;}

        .custommodal  .MuiBox-root .modalview_group div{ padding: 5px 0;}

        .MuiModal-root .MuiPaper-root .formCls {
            background: transparent!important;
            border-radius: 0!important;
            box-shadow: none!important;
            padding: 10px!important;
        }

        .MuiModal-root .MuiPaper-root .formCls .formbox{ margin: 10px 0;     width: 100%;}
        .titlewrpr:nth-child(1){width:49% !important;}

        @media (max-width: 998.98px){
            .titlewrpr:nth-child(1) {
                width: 100% !important;
            }
        }

        .formCls .MuiFormControl-root {
            padding: 0;
            flex: 1 0 32.33%;
            margin: 0 !important;
        }


 
       

        .ckediterblock .MuiInputLabel-root div {
            background: #e95e14;
            border-radius: 8px 8px 0 0;
            color: #fff;
            font-size: 16px;
            margin-bottom: 0;
            padding: 10px;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            z-index: 1;
        }
        
        .formCls .MuiFormControl-root>.MuiInputLabel-root,
        .formCls .formInline .formGroup>.MuiFormControl-root .MuiFormLabel-root {
            font-size: 16px !important;
        }
        
        .MuiFormLabel-filled {
            font-size: 14px !important;
            padding-left: 16px;
            text-transform: none;
            transform: translate(14px, 1px) scale(0.75) !important;
        }
        
        .formCls .formInline .formGroup>.MuiInputLabel-root .MuiFormLabel-root,
        .formCls .formInline .formGroup>.MuiFormControl-root .MuiFormLabel-root {
            position: absolute !important;
        }
        
        .formCls .MuiFormControl-root>.Mui-focused.MuiInputLabel-root,
        .formCls .formInline .formGroup>.MuiFormControl-root .Mui-focused.MuiFormLabel-root {
            transform: translate(14px, 1px) scale(0.75) !important;
            font-size: 14px !important;
        }
        
        .formCls .formInline .formGroup>.MuiInputLabel-root .MuiFormLabel-root,
        .formCls .formInline .formGroup>.MuiFormControl-root .MuiFormLabel-root {
            color: rgba(0, 0, 0, 0.6);
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            font-size: 10px;
            line-height: 1.4375em;
            letter-spacing: 0.00938em;
            padding: 0;
            position: absolute;
            display: block;
            transform-origin: top left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 24px);
            position: absolute;
            left: 0;
            top: 0;
            -webkit-transform: translate(14px, 16px) scale(1);
            -moz-transform: translate(14px, 16px) scale(1);
            -ms-transform: translate(14px, 16px) scale(1);
            transform: translate(14px, 16px) scale(1);
            -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
            transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
            z-index: 1;
            pointer-events: none;
        }
        
        .formCls .MuiFormControl-root .MuiOutlinedInput-root,
        .formCls .MuiFormControl-root textarea {
            height: 50px !important;
            font-size: 14px;
            border-radius: 5px;
           
            margin-top: 0;
            margin-bottom: 0;
             
            /* border-top-left-radius: 0;
            border-bottom-left-radius: 0; */
            flex: 1;
            overflow: hidden;
        }
        
 
        .formCls.otherStateCls {
            background: transparent;
            padding: 0;
            border-radius: 0;
            box-shadow: none !important;
        }
        
        .formCls.otherStateCls Button[type="button"] {
            margin-top: 0;
            margin-left: 20px;
        }
        
        .formCls .css-viou3o-MuiAutocomplete-root {
            width: 100%;
        }
        
        .formCls .formInline .formGroup.fullWidth {
            width: 100%;
        }
        
        .formActionBtn {
            justify-content: center;
            margin-top: 20px !important;
        }
        
        .formCls button {
            margin: 0 5px;
            color: rgb(8, 7, 7);
            font-size: 20px;
            /* background: rgb(87,53,117) !important;
            background: linear-gradient(90deg, rgba(87,53,117,1) 0%, rgba(139,34,11,1) 100%) !important;
            margin-top: 15px;
            box-shadow: 6px 5px 7px #8d616 */
            /* padding: 10px 34px; */
        }
        
        .formCls .MuiFormControl-root textarea {
            padding: 16.5px 14px;
        }
        
        .formCls .switchCls {
            display: flex;
            flex-direction: row-reverse;
        }

        .prioritystyle {
            /* margin-top: 10px; */
            margin-left: 20px;
            width: 38%;
        }
        @media (max-width: 1400px){
        .prioritystyle {
            width: 44%;
        }
        }
        @media (max-width: 998.98px){
            .prioritystyle{
            margin-left: 0px;
            width: 52%;
        }
        .activitycss {
            width: 44% !important;
            justify-content: flex-end;
        }
        }


        .activitycss {
            align-items: center;
    display: flex;
            /* margin-top: 10px; */
            padding: .5%;
            width: 10%;
        }
        @media (max-width: 1400px){
        .activitycss {
            width: 20%;
        }
        }


        .formCls .formInline {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 8px 0 6px;
            /* justify-content: space-between; */
          
        }
        .add_blgwrpr{
            /* width: 45% !important; */
            align-self: flex-start;
        }

        .upload_data_wrpr{
            /* width: 45% !important; */
        }




        .add_blgwrpr .MuiButton-root{
            padding: 10px 34px;
            background: #e0bbaf!important;
            background: linear-gradient(180deg,rgba(224,187,175,1) 0%,rgba(213,170,156,1) 50%,rgba(206,155,139,1) 50%,rgba(192,135,117,1) 100%)!important;
        }
            .search_bar_wrpr{
                background: #e95e14!important;
                color: #ffffff !important;
            /* background: linear-gradient(180deg,rgba(224,187,175,1) 0%,rgba(213,170,156,1) 50%,rgba(206,155,139,1) 50%,rgba(192,135,117,1) 100%)!important; */
            }
            .search_bar_wrpr p{
                font-weight: 600;
                padding: 5px 17px;
            }
            .searchbtn_wrpr{
                background: #e95e14!important;
                color: #ffffff !important;
                padding: 10px 34px !important;
            }

        .activitycss label div{ font-size: 18px; padding: 0 15px;}

        #createEventForm .submitbuttonwrapper {
            /* margin-top: 25px; */
            display: flex;
            justify-content: center; 
            width: 100%;
        }
        #createEventForm .submitbuttonwrapper button{    background: rgb(87,53,117) !important;
            background: linear-gradient(90deg, rgba(87,53,117,1) 0%, rgba(139,34,11,1) 100%) !important;
            margin-top: 15px;color: #ffffff;padding: 15px 30px;
        }

        .imageuploadDiv{ width: 100%; border: solid 1px #ccc; padding: 10px;}

        .imageuploadDiv h1{ font-size: 18px; margin: 0; padding: 0 0 10px 0;}

        .imageuploadDiv .fileUploadView{ width: 250px; max-width: 100%; border: solid 1px #ccc; padding: 5px; display: block; margin-bottom: 15px;}
        .imageuploadDiv button {
            background-color: #700c0c;
            border-radius: 5px;
            border: none;
            color: #fff;
            font-size: 16px; margin: 2px;
            padding: 8px 10px; cursor: pointer;
        } 
        @media screen and (max-width:1800px) {
            /* .listtableWrapper td{font-size: 14px;}
            .listtable_blog_wrapper .listtable_blog:nth-child(1) {
                width: 46.5%;}
                .listtable_blog_wrapper .listtable_blog:nth-child(2) {
                    width: 48.5%;} */
        }

        @media screen and (max-width:1199px) {
            .listtableWrapper .css-1gplja-MuiTable-root{min-width:inherit}

            .listtableWrapper  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{padding: 0; margin-left: -3px;} 
        

            .listtableWrapper thead{display:none}
            .listtableWrapper tbody,
            .listtableWrapper tr,
            .listtableWrapper td{display:block!important}
            .listtableWrapper tbody,
            .listtableWrapper tr,
            .listtableWrapper td{display:block; text-align: right;}
            .listtableWrapper tbody{padding:0}
            .listtableWrapper td{position:relative;padding:5px 10px; 	padding-left: 50%; text-align: left;  }
            .listtableWrapper tr{border:1px solid #b8c4d3!important}
            .listtableWrapper td:before{content: attr(title);
                float: left;
                font-weight: bold;
                text-transform: uppercase;      position: absolute;
                left: 10px; padding-top: 2px;}

                .listtableWrapper td button {
                    color: #e54e06;
                    margin: 2px 15px 0 0;
                    padding: 0;
                }
 
            .listtableWrapper .MuiTableCell-alignCenter button:first-of-type{padding-left:0}
            .listtableWrapper tr:last-child td{border-bottom:1px solid #b8c4d3!important;
                /* padding:5px 10px */
            }

            .listtableWrapper td:first-child, .listtableWrapper td:nth-child(2) {
                text-align: left!important;
            }
            .listtable_blog_wrapper{flex-direction: column;}
            .listtable_blog_wrapper .listtable_blog:nth-child(1){width: 100%;}
            .listtable_blog_wrapper .listtable_blog:nth-child(2){width: unset !important;}
            .listtable_blog_wrapper .listtable_blog{width: auto;}
        }
        

        @media (max-width:700px){
            .custommodal  .MuiBox-root {min-width:80% !important;}   
        }


        @media (max-width:580px) {
            

            .commonsection_backend {
                width: 96%!important;
            }

            .listtable_blog { 
                border: solid 8px #564f4c;
                border-radius: 4px;  
            }
            .modalview_group label:first-of-type{
                width: 47%;
            }
            
        }


        @media (max-width:380px) {
            .listtableWrapper .MuiToolbar-root{ display: block; text-align: center;}
            .add_blgwrpr .link_container .MuiFormControl-root .MuiOutlinedInput-root{font-size: 11.5px !important;}
        }

        .modal1 label{
        width: 75%;
/* display: flex; */
        }

        .modal2 .modalview_group label{
            width: 50% !important;
        }

        .modal2 .modalview_group .Email label:nth-child(2){word-break: break-word !important;}

        .modal1 label img{height: 100px; width: 100px;border: 3px solid rgb(146, 145, 145);margin: 0 2px;}


        .css-1wc848c-MuiFormHelperText-root {
            margin-top: 5px !important;
        }

/* <<<<<<<<<<<<....................reset(forgot)_password css start................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* .reset_password_wrpr .main_form_wrpr h1{text-align: center;} */

.reset_password_wrpr .submitbuttonmyacc {margin: 0 auto;}
.reset_password_wrpr .submitbuttonmyacc .MuiButton-root {
    font-size: 22px!important;
    color: #110c0b!important;
    padding: 16px 34px;
    margin-bottom: 15px;

}
.reset_password_wrpr .login_mainwrapper .formInline .email{display: flex;
    flex-direction: column;width: 100%;margin-bottom: 30px;}
.reset_password_wrpr .login_mainwrapper label{text-align: left;}
.reset_password_wrpr .formCls .formInline {justify-content: space-between;}
.reset_password_wrpr .forget_mainwrapper .login_mainwrapper input {background: #ffffff26;
    height: 50px;
    border: 1px solid #ffffff21;
    border-radius: 3px;
    font-size: 21px;
    padding: 0 5px;
    font-family: "mulish";
    color: #ffffff;
}
.reset_password_wrpr .formCls .MuiFormControl-root {border: 1px solid #ffffff21;background: #ffffff26;border-radius: 3px;}


.reset_password_wrpr .MuiInputLabel-root.Mui-focused{
    color: #ffffff !important;
    border:none;
}
.reset_password_wrpr .input:-webkit-autofill{
    -webkit-box-shadow:0}

 .reset_password_wrpr .main_form_wrpr {
    padding: 30px;
}   

.reset_password_wrpr .main_form_wrpr {
    padding: 30px !important;
}
/* <<<<<<<<<<<<....................reset(forgot)_password css end................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */


.toggle_modal .css-db57k8{background: #b8c4d3;border-radius: 15px;
    border: solid 5px #fff;}
.modal_button_Wrpr{display: flex;justify-content: center;
    align-items: center;}
    .modal_button_Wrpr button{margin: 2px;background: rgba(233,95,19,0.8) !important;color: #fff !important;}  
    

    @media (max-width:1199px){
    .toggle_modal .css-db57k8{width: 50%;}
    .toggle_modal .css-db57k8 h2{
    font-size: 18px!important}
    .toggle_modal .css-db57k8 .modalCloseBtn{right: -13px;top: -13px;}
    .dashboard_mainwrapper .MuiTypography-root {font-size: 20px;}
}

@media (max-width:700x){
.dashboard_mainwrapper .MuiTypography-root {
    font-size: 18px;
}
}




/* .loading_box{
    width: 100%;
} */


.login_mainwrapper input {
    caret-color: white;
}


