body {
  margin: 0;
  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiButton-text {
  font-weight: 600 !important;
  border-radius: 8px !important;
  background: #e0bbaf !important;
  background: linear-gradient(
    180deg,
    rgba(224, 187, 175, 1) 0%,
    rgba(213, 170, 156, 1) 50%,
    rgba(206, 155, 139, 1) 50%,
    rgba(192, 135, 117, 1) 100%
  ) !important;
  cursor: pointer !important;
  text-transform: none !important;
}
.MuiButton-root {
  line-height: unset !important;
  letter-spacing: unset !important;
}
.MuiTouchRipple-root {
  display: none;
}

/* font-family: 'Montserrat', sans-serif;
font-family: 'Mulish', sans-serif;
font-family: 'Roboto', sans-serif; */

/* --------------------------------- Start Header Css Start ---------------------------- */

.GoToHome {
  background: #b6b6b6 !important;
  border-radius: 100px 0 0 100px !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
  /* margin: 0!important; */
  /* min-width: inherit!important; */
  padding: 0px !important;
  position: fixed !important;
  right: -7px !important;
  top: 200px !important;
  z-index: 99 !important;
}
.GoToHome a {
  color: #e95e14;
  padding: 6px 9px 0 8px;
}

.GoToHomelogin a {
  color: #e95e14;
  padding: 2px 9px 0 8px;
}
.GoToHome .MuiSvgIcon-root {
  font-size: 35px;
}

.commonsection {
  width: 1440px;
  margin: 0 auto;
}

.footer_section .navbar .active::after {
  display: none;
}

@media (max-width: 1700px) {
  .commonsection {
    width: 90% !important;
  }
}

.common_bg_set {
  padding-top: 141px;
  margin-top: -141px;
}
@media (max-width: 812px) {
  .common_bg_set {
    padding-top: 107px;
    margin-top: -131px;
  }
}

.header_mainwrapper {
  padding-top: 30px;
  overflow: hidden;
}
.header_mainwrapper li {
  text-decoration: none;
  list-style-type: none;
}
.header_mainwrapper .header_subwrapper {
  width: 1548px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  background: #e72323;
  background: linear-gradient(
    90deg,
    rgba(231, 35, 35, 1) 0%,
    rgba(233, 95, 19, 1) 100%
  );
  border-radius: 8px;
  padding: 20px 35px;
  z-index: 9;
  position: relative;
}
.header_mainwrapper .navbar {
  width: 79%;
  position: relative;
}
.header_mainwrapper .navbar ul {
  display: flex;
  margin: 0;
  padding: 0;
}
.header_mainwrapper .navbar li {
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  margin: 0 2.5px;
}
/* .header_mainwrapper .navbar li a{text-decoration:none;color:#fff;font-size:18px;font-weight:500;font-family:"Roboto",sans-serif;padding:9px 17px} */
/* .header_mainwrapper .navbar li h6 {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  padding: 9px 17px;
  margin: 0 auto;
} */

.header_mainwrapper .navbar li a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  padding: 9px 17px;
  margin: 0 auto;
}
.header_mainwrapper .navbar .active,
.header_mainwrapper .navbar li:hover {
  background-color: #700c0c;
  border-radius: 5px;
}
.header_mainwrapper .navbar .active::after {
  content: "";
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/hover_arrow.png)
    no-repeat;
  position: absolute;
  width: 56px;
  height: 7px;
  bottom: -21px;
  left: 50%;
  transform: translateX(-50%);
}
.header_mainwrapper .header_socialconnect {
  cursor: pointer;
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.header_mainwrapper .header_socialconnect img {
  padding: 0 4px;
}
.header_mainwrapper .naVmobilemenu {
  display: none;
}

@media (max-width: 1199px) {
  .header_mainwrapper {
    padding-top: 0;
  }
  .header_mainwrapper .header_subwrapper {
    flex-wrap: wrap;
    width: 100% !important;
    border-radius: unset;
    padding: 0;
    flex-direction: row-reverse;
  }
  .header_mainwrapper .navbar {
    width: 100%;
  }
  .header_mainwrapper .navbar ul {
    flex-direction: column;
  }
  .header_mainwrapper .navbar .active::after {
    display: none;
  }
  .header_mainwrapper .navbar li a {
    margin: 0 auto;
    padding: 10px 17px;
    font-size: 18px;
  }
  .header_mainwrapper .navbar li::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 90%;
    background: #fffefe;
    background: linear-gradient( 90deg, rgba(255, 254, 254, 0.07046568627450978) 0%, rgb(197 163 174 / 91%) 50%, rgba(255, 254, 254, 0.07046568627450978) 99% );
    bottom: 0;
    /* right: 100px; */
  }
  .header_mainwrapper .naVmobilemenu {
    align-items: center;
    background: #700c0c;
    border-radius: 0;
    border-radius: 8px;
    box-shadow: inset 0 2px 5px #700c0c;
    display: flex;
    height: 40px;
    justify-content: center;
    padding: 5px;
    width: 60px;
    margin: 10px;
    order: 1;
    position: relative;
    z-index: 1;
  }
  .header_mainwrapper .header_socialconnect {
    width: 100%;
    justify-content: end;
    margin: 0 auto;
  }
  .header_mainwrapper .top_naVlists {
    display: none;
    order: 3;
  }
  .header_mainwrapper .top_naVlists.addcss {
    display: block;
    width: 100%;
  }
  .header_mainwrapper .addCSS {
    background: #c9d5df;
    background: linear-gradient(180deg, #dfe8ed, #c9d5df);
    border-radius: 0 0 10px 10px;
    display: block;
    margin-top: -9px;
    max-width: 100%;
    order: 3;
    width: 100%;
  }
  .header_mainwrapper .naVmobilemenu button {
    background: none !important;
    border: none !important;
  }
  .header_mainwrapper .naVmobilemenu svg {
    font-size: 2.5rem !important;
    fill: #fff !important;
  }
  .header_mainwrapper .login_block {
    width: 100%;
  }
  .header_mainwrapper .social_login_wrpr {
    margin-right: 10px;
  }
  .header_mainwrapper .navbar .active, .header_mainwrapper .navbar li:hover{
    border-radius: 0px;
    width: 100%;
    display: block;
    text-align: center;
  }
  .header_mainwrapper .navbar li{
    margin: 0px;
  }
}
@media (max-width: 381px) {
  .header_mainwrapper .header_subwrapper {
    flex-direction: column;
  }
  .header_mainwrapper .social_login_wrpr {
    padding-top: 10px;
  }
}

.header_mainwrapper .login_block {
  /* background: url(https://all-frontend-assets.s3.amazonaws.com/fryright_images/login_icon.png) no-repeat 10px; */
  display: flex;
  align-items: center;
  background-color: #fff6f6;
  border-radius: 50px;
  padding: 4px 17px;
  order: 4;
  cursor: pointer;
}
.header_mainwrapper .login_wrpr .img_wrpr {
  margin-left: 10px;
  width: 30px;
}

/* .header_mainwrapper .login_block a{
-webkit-text-decoration-skip: objects;
background-color: transparent;
border: none;
outline: none;
text-decoration: none;
transition: all .2s linear;
color: white;
font-size: 18px;
font-weight: 500;
font-family: "Roboto",sans-serif;
display: flex;
align-items: center;
justify-content: space-between;
flex-direction: row-reverse;
padding: 9px 17px;
} */

.header_mainwrapper .social_login_wrpr {
  display: flex;
  justify-content: flex-end;
}
.header_mainwrapper .login_wrpr {
  display: flex;
  align-items: center;
  color: #e95e14;
  font-size: 18px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

/* --------------------------------- Start Header Css End ---------------------------- */

/* --------------------------------- Start Home Css Start ---------------------------- */

.home_mainwrapper {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Timothy_Desmond_homepage.jpg)
    no-repeat;
  background-position: top center;
  background-size: cover;
}
.page_book_mainsection {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/home_banner_bg.webp)
    no-repeat;
  background-size: cover;
  background-position: left center;
}
.page_book_mainsection .youtube_link a {
  display: flex;
  text-decoration: none;
}
.home_mainwrapper .book_wrapper {
  position: relative;
}
.home_mainwrapper .book_bg {
  position: absolute;
  z-index: 1;
  top: 80px;
  left: 0;
}
.home_mainwrapper .book_section {
  z-index: 3;
  width: 51%;
}
.home_mainwrapper .book_section img {
  margin-left: 130px;
}
.home_mainwrapper .book_section_wrapper {
  display: flex;
  flex-wrap: nowrap;
}
.home_mainwrapper .book_details {
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/link_bg1.webp);
  background-repeat: no-repeat;
  border: 1px solid #c7917f;
  width: 46.5%;
  height: 611px;
  margin-top: 95px;
  border-radius: 25px;
  background-size: cover;
}
.home_mainwrapper .book_title h2 span {
  font-size: 51px;
  line-height: 64px;
  text-transform: uppercase;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/textbg_shadow_new1.jpg);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  display: block;
}
.home_mainwrapper .book_details_wrapper {
  margin: 30px 0 33px 130px;
  display: flex;
  flex-direction: column;
  z-index: 4;
  position: relative;
}
.home_mainwrapper .book_section_wrapper .youtube_link {
  background: #e72323;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  font-size: 26px;
  width: fit-content;
  margin-bottom: 46px;
  cursor: pointer;
}
.home_mainwrapper .book_section_wrapper .youtube_link h4 {
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  width: 100%;
  padding: 0 12px 0 0;
}
.home_mainwrapper .book_section_wrapper .youtube_link img {
  width: 19%;
}
.home_mainwrapper .book_title {
  margin-bottom: 36px;
}
.home_mainwrapper .amazon_link {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.home_mainwrapper .book_theory_section .amazon_link {
  margin-top: 44px;
  margin-left: 33px;
}
.home_mainwrapper .amazon_link .amazon_link_img {
  background: #fff;
  border-radius: 8px;
  margin: 0 16px 0 0;
  cursor: pointer;
}
.home_mainwrapper .amazon_link .amazon_link_img img {
  padding: 9px 23px;
  display: block;
  cursor: pointer;
}
.home_mainwrapper .amazon_link .amazon_link_text {
  margin: 0 0 0 16px;
}
.home_mainwrapper .amazon_link .amazon_link_text h3 {
  font-size: 32px;
  color: #fff;
  font-weight: 700;
}
.home_mainwrapper .socialwrapper_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.book_theory_section_main {
  padding-top: 70px;
}
.writer_bio_section {
  margin-top: -45px;
}
.writer_bio_section_wrapper {
  display: flex;
  padding: 35px 40px;
  justify-content: space-between;
  align-items: center;
  background: #2f2529;
  background: linear-gradient(
    180deg,
    rgba(47, 37, 41, 1) 0%,
    rgba(47, 37, 41, 0.7651435574229692) 56%,
    rgba(47, 37, 41, 0.11248249299719892) 99%
  );
  border-radius: 5px;
  position: relative;
}
.writer_bio_section .MuiButton-text {
  font-size: 30px !important;
  margin-top: 26px !important;
  padding: 14px 50px !important;
}
.home_mainwrapper .bio_title {
  font-size: 36px;
  text-transform: uppercase;
  color: #fff;
  line-height: 49px;
  margin-bottom: 34px;
}
.home_mainwrapper .bio_title span {
  color: #f29070;
}
.bio_text_section {
  padding-right: 45px;
}
.bio_text_section p {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 25px;
}
.bio_para {
  color: #fff;
}
.bio_text_section p span:nth-child(1) {
  color: #e38869;
}
.bio_text_section p span:nth-child(2) {
  color: #9996b1;
}
.writer_img_section {
  padding: 8px;
  position: relative;
  border: 1px solid #fff;
  border-radius: 8px;
  background-size: 100% 100%;
  margin-top: -131px;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/img_bg_layer.png);
  background-repeat: no-repeat;
}
.writer_img_section img {
  display: block;
}
.book_theory_section_wrapper {
  display: flex;
  justify-content: space-between;
}
.bookandLink_section {
  display: flex;
  flex-direction: column;
}
.bookimg_wrpr {
  margin-left: -90px;
}
.book_link_section {
  display: flex;
}
.home_mainwrapper .book_theory_section .amazon_link_text button {
  font-size: 28px;
  font-weight: 700;
  padding: 15px 13px;
  border-radius: 8px;
  background: #e0bbaf;
  background: linear-gradient(
    180deg,
    rgba(224, 187, 175, 1) 0%,
    rgba(213, 170, 156, 1) 50%,
    rgba(206, 155, 139, 1) 50%,
    rgba(192, 135, 117, 1) 100%
  );
  cursor: pointer;
}
.home_mainwrapper .book_theory_section .amazon_link_text {
  margin-right: 70px;
}
.stringtheory_section p {
  font-size: 18px;
  color: #fff;
  line-height: 28px;
  margin: 23px 0;
}
.book_theory_section .amazon_link_text .MuiButton-text {
  font-size: 28px !important;
  padding: 15px 22px !important;
  margin-top: 0 !important;
}
.book_review_section {
  margin-top: -46px;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/earth_half.webp),
    url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/quotation.png);
  background-repeat: no-repeat;
  background-position: top -70px right 333px, right 296px top 389px;
  padding-top: 80px;
}
.book_review_section_wrapper .reviewsection_title {
  border-radius: 3px;
  background: #1d0a0e;
  background: linear-gradient(
    90deg,
    rgba(29, 10, 14, 1) 0%,
    rgba(36, 21, 23, 0.21332282913165268) 100%
  );
  padding: 28px 33px;
  width: 40%;
  margin-bottom: 48px;
}
.book_review_section_wrapper h3 {
  font-size: 36px;
  color: #ede6e6;
  text-transform: uppercase;
}
.book_review_section_wrapper h5 {
  font-size: 24px;
  color: #f29070;
  margin: 20px 0 6px;
}
.book_review_section_wrapper h6 {
  font-size: 18px;
  color: #9996b1;
  position: relative;
  z-index: 999;
}
.book_review_section_wrapper p {
  font-size: 18px;
  color: #fff;
  margin: 18px 0 15px;
}
.book_review_section .book_review_section_wrapper {
  display: flex;
  flex-direction: column;
}
.book_review_section .reviews_wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.book_review_section .reviews_wrapper .reviews_section {
  width: 43.9%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px 4%;
  position: relative;
}
.book_review_section .reviews_wrapper .reviews_section:nth-child(4n + 1) {
  background: #190c0c;
  background: linear-gradient(
    90deg,
    rgba(25, 12, 12, 0) 0%,
    rgba(25, 12, 12, 0.6054796918767507) 99%
  );
  border-right: 1px solid #483b3c;
  padding-left: 0 !important;
}
.book_review_section
  .reviews_wrapper
  .reviews_section:nth-child(4n + 1)::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  right: 0;
  top: 0;
  background: #ff1e71;
  background: linear-gradient(
    90deg,
    rgba(255, 30, 113, 0) 0%,
    rgba(72, 59, 60, 0.8183648459383753) 94%,
    rgba(72, 59, 60, 1) 100%
  );
}
.book_review_section
  .reviews_wrapper
  .reviews_section:nth-child(4n + 1)::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #ff1e71;
  background: linear-gradient(
    90deg,
    rgba(255, 30, 113, 0) 0%,
    rgba(72, 59, 60, 0.8183648459383753) 94%,
    rgba(72, 59, 60, 1) 100%
  );
}
.book_review_section .reviews_wrapper .reviews_section:nth-child(4n + 2) {
  background: none;
}
.book_review_section .reviews_wrapper .reviews_section:nth-child(4n + 3) {
  background: none;
  padding-left: 0 !important;
}
.book_review_section .reviews_wrapper .reviews_section:nth-child(4n + 4) {
  background: #442f32;
  background: linear-gradient(
    90deg,
    rgba(68, 47, 50, 1) 0%,
    rgba(49, 32, 33, 0.0984768907563025) 70%
  );
}
.book_review_section
  .reviews_wrapper
  .reviews_section:nth-child(4n + 4)::before {
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #6d5a5d;
  background: linear-gradient(
    180deg,
    rgba(109, 90, 93, 0.07886904761904767) 0%,
    rgba(109, 90, 93, 1) 100%
  );
}
.book_review_section
  .reviews_wrapper
  .reviews_section:nth-child(4n + 4)::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  right: 0;
  bottom: 0;
  background: rgb(99, 80, 84);
  background: linear-gradient(
    270deg,
    rgba(99, 80, 84, 0) 0%,
    rgba(99, 80, 84, 0) 72%,
    rgba(99, 80, 84, 1) 100%
  );
}
.universe_img_section {
  margin-top: 38px;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/sunandplanet.webp);
  background-position: right top -30px;
  background-repeat: no-repeat;
  padding-bottom: 30px;
}
.universe_img_section .MuiButton-text {
  font-size: 28px;
  padding: 15px 22px;
}
.universerimg_section_wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.universerimg_section_wrapper_img {
  position: absolute;
  left: -239px;
  bottom: -320px;
}
.universe_img img {
  margin-top: -320px;
  margin-left: -239px;
}
.ancienttext_section {
  display: flex;
  flex-direction: column;
  padding-left: 43%;
}
.ancienttext_section_text {
  margin-bottom: 43px;
  display: flex;
  flex-direction: column;
}
.ancienttext_section_text h1 span {
  font-size: 64px;
  text-transform: uppercase;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/textbg_shadow_new1.jpg);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  display: block;
  letter-spacing: -2px;
  word-spacing: 4px;
}
.ancienttext_section_text h2 span {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/text_brownbg2.png);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: block;
  font-size: 56px;
  text-transform: uppercase;
  margin-bottom: 33px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.ancienttext_section_text p {
  font-size: 18px;
  color: #fff;
  line-height: 30px;
  position: relative;
}
.ancienttext_section_text p span {
  color: #f29070;
}
.home_mainwrapper .universe_img_section .amazon_link_text button {
  font-size: 28px;
  font-weight: 600;
  padding: 15px 24px;
  border-radius: 8px;
  background: #e0bbaf;
  background: linear-gradient(
    180deg,
    rgba(224, 187, 175, 1) 0%,
    rgba(213, 170, 156, 1) 50%,
    rgba(206, 155, 139, 1) 50%,
    rgba(192, 135, 117, 1) 100%
  );
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.home_mainwrapper .universe_img_section .amazon_link {
  justify-content: unset;
}
.triplebookcopy_section {
  margin-top: 95px;
}
.triplebookcopy_section_wrapper {
  display: flex;
  justify-content: space-between;
  background: #2d1213;
  background: linear-gradient(
    180deg,
    rgba(45, 18, 19, 0.26934523809523814) 0%,
    rgba(45, 18, 19, 1) 100%
  );
  border-radius: 3px;
  align-items: center;
}
.triplebookcopy_text {
  width: 43%;
  display: flex;
  flex-direction: column;
  margin-bottom: 83px;
  justify-content: space-between;
  margin-left: 60px;
}
.triplebookcopy_text h4 span {
  font-size: 48px;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/textbg_shadow_new1.jpg);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  display: block;
}
.triplebookcopy_img {
  width: 56%;
}
.triplebookcopy_img img {
  width: 100%;
  display: block;
}
.home_mainwrapper .triplebookcopy_section .amazon_link {
  justify-content: unset;
  margin-top: 12px;
}
.triplebookcopy_section .amazon_link_text {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/arrowshadow.png)
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35.5px 69px !important;
  font-size: 39px;
  font-weight: 700;
  color: #3e232d;
  cursor: pointer;
}
.triplebookcopy_section .amazon_link_text .MuiButton-text {
  font-size: 39px;
  font-weight: 700;
  color: #3e232d;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}
.universe_img_section .amazon_link .amazon_link_img img {
  cursor: pointer;
  z-index: 1;
  position: relative;
}


/* ========================================BLOG VIDEO SELECTOR================================== */
.carousel .thumb.selected, .carousel .thumb:hover {
border: 3px solid #fff !important;
}
.carousel .thumb{
border: 3px solid #333 !important;    
}


.descriptionPtag p {
  display: block;
  margin-bottom: 20px;
}
.descriptionPtag p:first-child{ margin-bottom: 0;}
.descriptionPtag p:last-of-type{ margin-bottom: 0;}

html {
  scroll-behavior: smooth;
}



/* <----------------------------Home_page Responsive start-----------------------------> */

@media (max-width: 1700px) {
  .universe_img_section {
    background-position: right -141px top -30px;
  }
  .triplebookcopy_text h4 span {
    font-size: 45px;
  }
  .home_mainwrapper .book_details {
    margin-left: -20px;
  }
  .home_mainwrapper .book_section img {
    margin-left: 140px;
  }
  .home_mainwrapper .book_bg {
    left: 10px;
  }
}
@media (max-width: 1600px) {
  .stringtheory_section p {
    margin: 11px 0;
  }
  .footer_socialconnect {
    left: 0;
  }
}
@media (max-width: 1542px) {
  .home_mainwrapper .bio_title {
    margin-bottom: 40px;
  }
  .universerimg_section_wrapper_img {
    left: -285px;
  }
  .triplebookcopy_text h4 span {
    font-size: 40px;
  }
  .home_mainwrapper .book_bg {
    top: 60px;
    left: 60px;
  }
  .home_mainwrapper .book_bg img {
    width: 80%;
  }
  .home_mainwrapper .book_section img {
    width: 90%;
  }
  .home_mainwrapper .book_details {
    width: 45.5%;
    max-height: 500px;
    margin-left: -80px;
  }
  .home_mainwrapper .book_title h2 span {
    font-size: 40px;
    line-height: 50px;
  }
  .home_mainwrapper .book_title {
    margin-bottom: 25px;
  }
  .home_mainwrapper .book_section_wrapper .youtube_link {
    font-size: 18px;
    margin-bottom: 25px;
  }
  .home_mainwrapper .amazon_link .amazon_link_text h3 {
    font-size: 21px;
  }
}
@media (max-width: 1500px) {
  .home_mainwrapper .book_title {
    margin-bottom: 25px;
  }
  .home_mainwrapper .book_title h2 span {
    font-size: 45px;
    line-height: 50px;
  }
  .home_mainwrapper .amazon_link .amazon_link_text h3 {
    font-size: 25px;
  }
  /* .home_mainwrapper .book_bg{top:60px;left:60px} */
  /* .home_mainwrapper .book_bg img{width:80%} */
  /* .home_mainwrapper .book_section img{width:90%} */
  /* .home_mainwrapper .book_details{width:45.5%;max-height:500px;margin-left:-80px} */
  .home_mainwrapper .book_section_wrapper .youtube_link {
    padding: 10px 20px;
    font-size: 23px;
    margin-bottom: 30px;
  }
  .writer_bio_section {
    margin-top: 0;
  }
}
@media (max-width: 1473px) {
  .home_mainwrapper .bio_title {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 25px;
  }
  .stringtheory_section p {
    margin: 18px 0;
    font-size: 18px;
    line-height: 26px;
  }
  .triplebookcopy_section .amazon_link_text .MuiButton-text {
    font-size: 38px;
  }
}

@media (max-width: 1445px) {
  .triplebookcopy_section .amazon_link_text .MuiButton-text {
    font-size: 35px;
  }
}
@media (max-width: 1431px) {
  .ancienttext_section_text h2 span {
    font-size: 50px;
  }
}
@media (max-width: 1414px) {
  .triplebookcopy_section .amazon_link_text {
    padding: 35.5px 60px !important;
  }
}
@media (max-width: 1409px) {
  .triplebookcopy_text h4 span {
    font-size: 40px;
  }
  .triplebookcopy_section .amazon_link_text .MuiButton-text {
    font-size: 34px;
  }
}
@media (max-width: 1400px) {
  .home_mainwrapper .book_bg img {
    width: 63%;
  }
  .home_mainwrapper .book_section img {
    width: 500px;
  }
  .home_mainwrapper .book_title h2 span {
    font-size: 38px;
    line-height: 43px;
  }
  .home_mainwrapper .book_title {
    margin-bottom: 15px;
  }
  .home_mainwrapper .book_section_wrapper .youtube_link {
    margin-bottom: 25px;
  }
  .home_mainwrapper .book_details {
    height: 409px;
    margin-left: -110px;
    margin-top: 62px;
  }
  .home_mainwrapper .book_details_wrapper {
    margin: 30px 9px 33px 110px;
  }
  .home_mainwrapper .amazon_link .amazon_link_img {
    margin: 0 10px 0 0;
  }
  .home_mainwrapper .amazon_link .amazon_link_img img {
    padding: 8px 16px;
  }
  .home_mainwrapper .amazon_link .amazon_link_text h3 {
    font-size: 19px;
  }
  .book_theory_section_wrapper {
    flex-direction: column;
  }
  .bookandLink_section {
    width: 100%;
    margin: 0 auto;
  }
  .bookimg_wrpr {
    margin: 0 auto !important;
  }
  .bookimg_wrpr img {
    width: 100%;
  }
  .home_mainwrapper .book_theory_section .amazon_link_text {
    margin: 0 auto !important;
  }
  .stringtheory_section {
    margin-top: 30px;
  }
  .home_mainwrapper .bio_title {
    line-height: 39px;
    margin-bottom: 30px;
  }
  .stringtheory_section p {
    line-height: 27px;
    margin: 15px 0;
  }
  .home_mainwrapper .book_theory_section .amazon_link {
    margin: 0 auto;
  }
  .ancienttext_section {
    width: 47%;
  }
  .ancienttext_section_text h1 span {
    font-size: 56px;
  }
  .ancienttext_section_text h2 span {
    font-size: 43px;
    margin-bottom: 24px;
  }
  .ancienttext_section {
    padding-left: 50%;
  }
  .page_book_mainsection {
    padding-bottom: 200px;
    margin-bottom: -200px;
  }
}
@media (max-width: 1385px) {
  .home_mainwrapper .universe_img_section .amazon_link_text button {
    font-size: 26px;
    padding: 15px 20px;
  }
  .triplebookcopy_text h4 span {
    font-size: 36px;
  }
}
@media (max-width: 1322px) {
  .universe_img_section .MuiButton-text {
    font-size: 27px;
    padding: 15px 17px;
  }
  .home_mainwrapper .book_bg {
    top: 60px;
    left: 75px;
  }
  .home_mainwrapper .book_section img {
    width: 465px;
  }
  .home_mainwrapper .book_details {
    width: 43.5%;
  }
  .home_mainwrapper .book_details_wrapper {
    margin: 30px 9px 33px 100px;
  }
  .home_mainwrapper .book_title h2 span {
    font-size: 35px;
    line-height: 40px;
  }
}
@media (max-width: 1312px) {
  .home_mainwrapper .universe_img_section .amazon_link_text button {
    font-size: 23px;
    padding: 15px;
  }
}
@media (max-width: 1306px) {
  .triplebookcopy_section .amazon_link_text .MuiButton-text {
    font-size: 29px;
  }
}
@media (max-width: 1275px) {
  .universe_img_section .MuiButton-text {
    font-size: 24px;
  }
}
@media (max-width: 1270px) {
  /* .home_mainwrapper .book_bg{top:60px;left:75px} */
  /* .home_mainwrapper .book_section img{width:465px} */
  .home_mainwrapper .book_details {
    height: 415px;
    margin-left: -100px;
  }
  /* .home_mainwrapper .book_details_wrapper{margin:30px 9px 33px 100px} */
  /* .home_mainwrapper .book_title h2 span{font-size:35px;line-height:40px} */
  .home_mainwrapper .amazon_link .amazon_link_img img {
    padding: 6px 12px;
  }
  /* .home_mainwrapper .book_details{width:43.5%} */
}
@media (max-width: 1240px) {
  .triplebookcopy_section .amazon_link_text {
    padding: 30px 53px !important;
  }
  .home_mainwrapper .book_details {
    height: 415px;
    margin-left: -74px;
  }
}
@media (max-width: 1199px) {
  .home_mainwrapper .book_section_wrapper {
    flex-direction: column;
  }
  .home_mainwrapper .book_bg {
    top: 40%;
    left: 110px;
    transform: translate(0%, -47%);
  }
  .home_mainwrapper .book_bg img {
    width: 80%;
  }
  .home_mainwrapper .book_section {
    width: 100%;
  }
  .home_mainwrapper .book_section img {
    width: 58%;
    margin-left: 205px;
  }
  .home_mainwrapper .book_details {
    width: 100%;
    margin: -30px 0 0;
    height: auto;
  }
  .home_mainwrapper .book_details_wrapper {
    margin: 15px;
  }
  .home_mainwrapper .book_title h2 span {
    display: unset;
    font-size: 30px;
  }
  .home_mainwrapper .book_title {
    text-align: center;
  }
  .home_mainwrapper .book_section_wrapper .youtube_link {
    margin: 0 auto;
  }
  .home_mainwrapper .amazon_link {
    margin: 0 auto;
  }
  .home_mainwrapper .amazon_link .amazon_link_img {
    height: 60px;
    margin: 7px;
  }
  .writer_bio_section_wrapper {
    flex-direction: column-reverse;
    background: linear-gradient(
      180deg,
      rgba(47, 37, 41, 1) 90%,
      rgba(47, 37, 41, 0.7651435574229692) 99%,
      rgba(47, 37, 41, 0.11248249299719892) 99%
    );
  }
  .bio_text_section {
    padding-right: 0;
  }
  .writer_img_section {
    margin-top: 0;
    margin-bottom: 30px;
  }
  .writer_bio_section {
    margin-top: 50px;
  }
  .book_review_section_wrapper .reviewsection_title {
    width: auto;
    margin-bottom: 20px;
  }
  .book_review_section .reviews_wrapper {
    flex-direction: column;
  }
  .book_review_section .reviews_wrapper .reviews_section:nth-child(4n + 1) {
    padding-left: 4% !important;
  }
  .book_review_section .reviews_wrapper .reviews_section:nth-child(4n + 3) {
    padding-left: 4% !important;
  }
  .book_review_section .reviews_wrapper .reviews_section {
    width: auto;
    padding: 14px 4%;
  }
  .book_theory_section {
    margin-top: 11px;
  }
  .universe_img_section {
    margin-top: 160px;
    background-image: none;
  }
  .universerimg_section_wrapper {
    flex-direction: column;
  }
  .universe_img {
    width: 100%;
  }
  .universe_img img {
    margin: 0 auto;
    display: block;
    width: 100%;
  }
  .ancienttext_section {
    width: 100%;
    margin-top: -125px;
    padding-left: 0;
  }
  .triplebookcopy_section {
    margin-top: 55px;
  }
  .triplebookcopy_section_wrapper {
    flex-direction: column;
  }
  .triplebookcopy_text {
    width: 100%;
    margin: 0 auto;
  }
  .triplebookcopy_text h4 span {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .triplebookcopy_text h4 span br {
    display: none;
  }
  .triplebookcopy_img {
    width: 100%;
  }
  .triplebookcopy_img img {
    margin: 0 auto;
    display: block;
    width: 100%;
  }
  .universerimg_section_wrapper_img {
    position: inherit;
    left: inherit;
    bottom: inherit;
    width: 100%;
    display: none;
  }
  .universerimg_section_wrapper_img img {
    transform: translateX(-7%);
    width: 100%;
  }
  .triplebookcopy_section .amazon_link_text {
    background-image: none;
    background: #e0bbaf;
    background: linear-gradient(
      180deg,
      rgba(224, 187, 175, 1) 0%,
      rgba(213, 170, 156, 1) 50%,
      rgba(206, 155, 139, 1) 50%,
      rgba(192, 135, 117, 1) 100%
    );
    padding: 15px 24px !important;
    border-radius: 8px;
    height: 31px;
    margin: 7px;
  }
  .home_mainwrapper .triplebookcopy_section .amazon_link {
    margin-bottom: 30px;
  }
  .book_theory_section_wrapper {
    margin-top: -54px;
  }
  .page_book_mainsection {
    background-position: left 29% top -223px;
    padding-bottom: 500px;
    margin-bottom: -474px;
    position: relative;
  }
  .page_book_mainsection::after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
  }
  .home_mainwrapper .book_wrapper {
    position: relative;
    z-index: 1;
  }
  .book_review_section {
    padding-top: 50px;
  }
}
@media (max-width: 1140px) {
  .home_mainwrapper .book_bg {
    top: 40%;
    transform: translate(15%, -52%);
  }
  .home_mainwrapper .book_bg img {
    width: 63%;
  }
  .home_mainwrapper .book_section img {
    width: 50%;
    transform: translate(12%, 0%);
  }
}
/* @media (max-width: 1100px) {
  .home_mainwrapper .book_bg {
    transform: translate(15%, -51%);
  }
} */
@media (max-width: 1048px) {
  .home_mainwrapper .book_title h2 span {
    font-size: 22px;
  }
  .home_mainwrapper .book_bg img {
    width: 56%;
}
}
@media (max-width: 900px) {
  .home_mainwrapper .book_bg {
    transform: translate(7%, -64%);
  }
  .home_mainwrapper .book_bg img {
    width: 50%;
}
  .home_mainwrapper .book_section img {
    margin-left: 130px;
  }
  .home_mainwrapper .book_section_wrapper .youtube_link {
    margin-bottom: 20px;
  }
  .home_mainwrapper .book_wrapper {
    margin-top: 33px;
  }
  .home_mainwrapper .socialwrapper_section {
    flex-direction: column;
  }
}
/* @media (max-width: 837px) {
  .home_mainwrapper .book_bg {
    transform: translate(9%, -65%);
  }
} */
@media (max-width: 777px) {
  .home_mainwrapper .book_title h2 span {
    font-size: 20px;
    line-height: 20px;
    white-space: nowrap;
  }
}
@media (max-width: 700px){
.home_mainwrapper .book_bg {
    transform: translate(4%, -78%);
}
.home_mainwrapper .book_bg img {
  width: 45%;
}
}
@media (max-width: 680px) {
  /* .home_mainwrapper .book_bg {
    transform: translate(1%, -75%);
  } */
  .home_mainwrapper .book_section img {
    margin-left: 100px;
  }
  .home_mainwrapper .amazon_link .amazon_link_text {
    text-align: center;
    margin: 0;
  }
  .universe_img_section {
    background-position: right -76px top -55px;
  }
  .ancienttext_section_text h1 span {
    font-size: 35px;
  }
  .ancienttext_section_text h2 span {
    font-size: 30px;
  }
  .triplebookcopy_text h4 span {
    font-size: 30px;
  }
  .home_mainwrapper .amazon_link {
    flex-direction: column;
  }
  .home_mainwrapper .amazon_link .amazon_link_img {
    margin-bottom: 20px !important;
  }
  .page_book_mainsection {
    background-position: left 35% top -319px;
    padding-bottom: 994px;
    margin-bottom: -947px;
  }
  .writer_bio_section .MuiButton-text {
    font-size: 22px !important;
  }
  .book_review_section_wrapper h3 {
    font-size: 28px;
  }
  .writer_bio_section {
    margin-top: 0;
  }
}
/* @media (max-width: 646px) {
  .home_mainwrapper .book_bg {
    transform: translate(2%, -73%);
  }
}
@media (max-width: 624px) {
  .home_mainwrapper .book_bg {
    transform: translate(2%, -87%);
  }
} */
@media (max-width: 580px) {
  .home_mainwrapper .bio_title {
    font-size: 30px;
    text-align: center;
  }
  .page_book_mainsection::after {
    background-color: rgba(0, 0, 0, 0.8);
  }
}
@media (max-width: 573px) {
  .home_mainwrapper .book_bg {
    transform: translate(2%, -94%);
  }
  .home_mainwrapper .book_section img {
    width: 50%;
    transform: translate(12%, -2%);
  }
}
@media (max-width: 480px) {
  .home_mainwrapper .book_section img {
    margin-left: 85px;
  }
  .home_mainwrapper .book_bg {
    transform: translate(-5%, -108%);
  }
  .socialwrapper_section {
    flex-direction: column;
  }
  .home_mainwrapper .amazon_link .amazon_link_text {
    padding: 0;
    text-align: center;
  }
  .home_mainwrapper .book_details {
    margin: -20px 0 0;
  }
  .writer_img_section img {
    width: 100%;
  }
  .ancienttext_section {
    margin-top: -120px;
  }
  .universe_img_section {
    background-position: right -76px top -100px;
  }
  .book_theory_section .amazon_link_text .MuiButton-text {
    font-size: 21px !important;
    padding: 15px !important;
  }
  .book_review_section_wrapper .reviewsection_title {
    padding: 28px 20px;
  }
  .page_book_mainsection {
    background: none;
  }
}
@media (max-width: 421px) {
  .home_mainwrapper .book_bg {
    transform: translate(-6%, -133%);
  }
}
@media (max-width: 400px) {
  .home_mainwrapper .book_section img {
    margin-left: 55px;
  }
  .home_mainwrapper .book_bg {
    transform: translate(-17%, -133%);
}
  .bio_button {
    font-size: 20px;
    padding: 20px 44px;
  }
}
@media (max-width: 360px) {
  .home_mainwrapper .book_bg {
    transform: translate(-21%, -158%);
  }
  /* .home_mainwrapper .book_bg img {
    width: 65%;
  } */
  .home_mainwrapper .amazon_link .amazon_link_text h3 {
    font-size: 16px;
  }
  .writer_bio_section .MuiButton-text {
    padding: 14px 55px !important;
    font-size: 18px !important;
  }
}

/* <----------------------------Home_page Responsive end-----------------------------> */

/* --------------------------------- Home Css End -------------------------------------------------- */

/* --------------------------------- Start Bio Css Start ---------------------------- */

.biopage_mainwrapper {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Bio_page/main_biopage_background.jpg)
    no-repeat;
  background-position: left top;
  background-size: cover;
}
.biopage_banner_mainwrapper {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Bio_page/banner_bg_sun.webp),
    url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Bio_page/banner_bg_moon.webp);
  background-repeat: no-repeat, no-repeat;
  background-position: top 74% left -39%, top -67% right;
  background-size: auto, auto;
}
.biopage_mainwrapper .biopage_banner_subwrapper {
  display: flex;
}
.biopage_mainwrapper .biopage_text_section {
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Bio_page/bio_bannertxt_wrpr.webp);
  background-repeat: no-repeat;
  border: 1px solid #c7917f;
  width: 55%;
  height: 425px;
  margin-top: 85px;
  border-radius: 0 41px 41px 0;
  background-size: cover;
}
.biobanner_book_logo {
  width: 32%;
  margin-left: 37px;
  z-index: 3;
  margin-top: 35px;
}
.biopage_mainwrapper .book_title {
  padding: 55px 75px;
  position: relative;
  z-index: 4;
}
.biopage_mainwrapper .book_title h1 span {
  font-size: 40px;
  text-transform: uppercase;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/textbg_shadow_new1.jpg);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  line-height: 63px;
}
.biopage_mainwrapper .book_title h3 {
  font-size: 34px;
  font-weight: 700;
  color: #f29070;
  line-height: 48px;
}
.biopage_mainwrapper .writer_bio_section .bio_title {
  font-size: 36px;
  color: #fff;
  margin-bottom: 48px;
}
.biopage_mainwrapper .writer_bio_section p span {
  color: #f29070 !important;
}
.biopage_mainwrapper .writer_bio_section_wrapper {
  margin-top: 80px;
  padding: 3px 40px;
}
.biopage_mainwrapper .writer_img_section {
  margin-top: -77px;
}
.biopage_mainwrapper .bio_text_section {
  padding-right: 81px;
}
.biopage_mainwrapper .quotation_section {
  margin-top: 50px;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Bio_page/quotation_bg.webp);
  background-repeat: no-repeat;
  background-position: left -73px top -72px;
  padding-top: 150px;
  margin-top: -100px;
  padding-bottom: 100px;
  margin-bottom: -100px;
}
.biopage_mainwrapper .quotation_section_text {
  color: #f29070;
  font-weight: 700;
  margin-left: 35%;
  background: #300e11;
  background: linear-gradient(
    345deg,
    rgba(48, 14, 17, 0) 0%,
    rgba(48, 14, 17, 0.9920343137254902) 100%
  );
  border-radius: 10px;
  position: relative;
}
.biopage_mainwrapper .quotation_section_text h2 {
  padding: 110px 50px;
  font-size: 30px;
  line-height: 47px;
}
.biopage_mainwrapper .quotation_section_text h2 span {
  position: relative;
}
.biopage_mainwrapper .quotation_section_text::before {
  content: "";
  position: absolute;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Bio_page/invertedquoma_1.png);
  background-repeat: no-repeat;
  top: 34px;
  left: 42px;
  height: 100px;
  width: 100px;
}
.biopage_mainwrapper .quotation_section_text h2 span::after {
  content: "";
  position: absolute;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Bio_page/invertedquoma_2.png);
  background-repeat: no-repeat;
  top: 16px;
  right: -116px;
  height: 100px;
  width: 100px;
}
.biopage_mainwrapper .booklink_section {
  margin-top: -20px;
}
.biopage_mainwrapper .booklink_section_mainwrapper {
  background: #300e11;
  background: linear-gradient(
    180deg,
    rgba(48, 14, 17, 0) 0%,
    rgba(55, 28, 33, 1) 100%
  );
}
.biopage_mainwrapper .booklink_section_wrapper {
  display: flex;
  align-items: center;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Bio_page/last_bookbg_clouds.webp);
  background-repeat: no-repeat;
  background-position: right bottom -42px;
}
.biopage_mainwrapper .bookcopy_text {
  width: 53%;
  padding-left: 54px;
  margin-bottom: -5px;
}
.biopage_mainwrapper .bookcopy_text p {
  font-size: 18px;
  color: #fff;
  line-height: 24px;
  margin-bottom: 27px;
}
.biopage_mainwrapper .bookcopy_img {
  width: 43%;
  margin-bottom: -58px;
}
.biopage_mainwrapper .amazon_link {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 39px;
}
.biopage_mainwrapper .amazon_link_img {
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 30px;
}
.biopage_mainwrapper .amazon_link_img img {
  display: block;
  padding: 10px 32px 7px;
}
.biopage_mainwrapper .amazon_link_text {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/arrowshadow.png)
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35.5px 69px !important;
  font-size: 39px;
  font-weight: 700;
  color: #3e232d;
  cursor: pointer;
}
.biopage_mainwrapper .amazon_link_text .MuiButton-text {
  font-size: 39px;
  font-weight: 700;
  color: #3e232d;
  border: none;
  background: none !important;
  cursor: pointer;
  padding: 0;
}

/* --------------------------------- Start Bio Responsive ---------------------------- */

@media (max-width: 1700px) {
  .biobanner_book_logo img {
    margin-left: 6%;
  }
  .biopage_mainwrapper .quotation_section_text {
    margin-left: 40%;
  }
  .biopage_mainwrapper .book_title {
    padding: 50px 26px 40px 75px;
  }
}
@media (max-width: 1550px) {
  .biopage_mainwrapper .quotation_section_text {
    margin-left: 46%;
  }
  .biopage_mainwrapper .bookcopy_img img {
    max-width: 100%;
  }
}
@media (max-width: 1500px) {
  .biopage_mainwrapper .book_title {
    padding: 65px 30px 45px 88px;
  }
  .biopage_mainwrapper .book_title h1 span {
    font-size: 36px;
    line-height: 56px;
  }
  .biopage_mainwrapper .biopage_text_section{
    height: 405px;
    margin-top: 115px;
  }
}
@media (max-width: 1437px) {
  .biopage_mainwrapper .book_title h1 span {
    font-size: 32px;
  }
  .biopage_mainwrapper .book_title {
    padding: 65px 30px 45px 100px;
}
}
@media (max-width: 1400px) {
  .biopage_mainwrapper .quotation_section_text {
    margin-left: 50%;
  }
  .biopage_mainwrapper .quotation_section_text h2 {
    font-size: 28px;
    line-height: 40px;
  }
}
@media (max-width: 1350px) {
  .biopage_mainwrapper .book_title {
    padding: 55px 25px 45px 130px;
  }
  .biopage_mainwrapper .book_title h1 span {
    font-size: 28px;
  }
  .biopage_mainwrapper .book_title h3 {
    font-size: 29px;
    line-height: 42px;
  }
  .biopage_mainwrapper .bookcopy_text p {
    line-height: 22px;
    margin-bottom: 17px;
  }
  .biopage_mainwrapper .amazon_link {
    margin-top: 0;
    justify-content: center;
  }
  .biopage_mainwrapper .biopage_text_section {
    height: 380px;
    margin-top: 132px;
  }
  .biopage_mainwrapper .book_title h1 span {
    /* font-size: 40px; */
    line-height: 52px;
  }
}
@media (max-width: 1300px) {
  .biopage_mainwrapper .quotation_section_text {
    margin-left: 53%;
  }
  .biopage_mainwrapper .quotation_section_text h2 {
    font-size: 26px;
    line-height: 34px;
  }
}
@media (max-width: 1260px) {
  .biopage_mainwrapper .book_title h1 span {
    font-size: 26px;
  }
  .biopage_mainwrapper .book_title {
    padding: 45px 25px 45px 150px;
}
}
@media (max-width: 1199px) {
  .biopage_mainwrapper .biopage_banner_subwrapper {
    flex-direction: column;
  }
  .biobanner_book_logo {
    margin-left: 0;
    width: 100%;
  }
  .biobanner_book_logo img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
  .biopage_mainwrapper .biopage_text_section {
    width: 100%;
    height: auto;
    border-radius: 0;
    margin: -15px 0 0;
  }
  .biopage_mainwrapper .book_title {
    padding: 25px;
  }
  .biopage_mainwrapper .book_title h1 span {
    display: unset;
  }
  .biopage_mainwrapper .book_title h3 br {
    display: none;
  }
  .biopage_mainwrapper .writer_img_section {
    margin-top: 20px;
  }
  .biopage_mainwrapper .booklink_section_wrapper {
    flex-direction: column;
  }
  .biopage_mainwrapper .bookcopy_text {
    width: 100%;
    padding-left: 0;
  }
  .biopage_mainwrapper .amazon_link_text {
    background-image: none;
    background: #e0bbaf;
    background: linear-gradient(
      180deg,
      rgba(224, 187, 175, 1) 0%,
      rgba(213, 170, 156, 1) 50%,
      rgba(206, 155, 139, 1) 50%,
      rgba(192, 135, 117, 1) 100%
    );
    padding: 13px 25px !important;
  }
  .biopage_mainwrapper .amazon_link_text .MuiButton-text {
    font-size: 28px;
  }
  .biopage_mainwrapper .bookcopy_img {
    width: 100%;
    margin-bottom: -75px;
    margin-top: 30px;
  }
  .biopage_mainwrapper .bookcopy_img img {
    display: block;
    margin: 0 auto;
  }
  .biopage_mainwrapper .book_title h1 span {
    font-size: 30px;
    line-height: 45px;
  }
  .biopage_mainwrapper .quotation_section_text {
    margin-left: 0;
  }
  .biopage_mainwrapper .quotation_section_text h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .biopage_mainwrapper .quotation_section {
    background-position: center top;
    background-size: 88%;
    padding-top: 66%;
    margin-top: -14%;
  }
  .biopage_mainwrapper .bio_text_section {
    padding-right: 0px;
  }
}

@media (max-width: 980px){
  .biopage_mainwrapper .book_title h1 span {
    font-size: 25px;
    line-height: 35px;
}
}
@media (max-width: 580px) {
  .biopage_mainwrapper .amazon_link {
    flex-direction: column;
  }
  .biopage_mainwrapper .amazon_link_img {
    margin: 0 0 20px;
  }
  .biopage_mainwrapper .quotation_section_text h2 {
    padding: 65px 20px;
    font-size: 25px;
    line-height: 35px;
  }
  .biopage_mainwrapper .quotation_section_text::before {
    top: 13px;
    left: 19px;
  }
  .biopage_mainwrapper .bookcopy_img {
    margin-bottom: 0px;
  }
  .biopage_mainwrapper .book_title h1 span {
    font-size: 25px;
  }
}

/* --------------------------------- End Bio Responsive ---------------------------- */

/* --------------------------------- Bio Css End ---------------------------- */

/* --------------------------------- Start Broadcast TrainingCss Start ---------------------------- */

.broadcasttrainings_mainwrapper {
  background-color: #190c0c;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Bio_page/Timothy-Desmond-Broadcasts-full-background_new.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
}
.broadcasttrainings_mainwrapper .broadcast_banner_mainwrapper {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/Broadbanner_bg.webp);
  background-repeat: no-repeat;
  background-position: left -100px center;
}
.broadcasttrainings_mainwrapper .broadcast_banner_subwrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.broadcasttrainings_mainwrapper .observable_universe_logo {
  margin-top: 33px;
  margin-left: 42px;
  margin-bottom: 30px;
  width: 45%;
}
.broadcasttrainings_mainwrapper .broadcast_logo_section {
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/banner_section_broadcast_bg.webp);
  background-repeat: no-repeat;
  border: 1px solid #c7917f;
  width: 49%;
  height: 285px;
  margin-top: 40px;
  border-radius: 0 41px 41px 0;
  background-size: cover;
  margin-right: 27px;
}
.broadcasttrainings_mainwrapper .book_title {
  padding: 40px;
}
.broadcasttrainings_mainwrapper .book_title h2 span {
  font-size: 83px;
  line-height: 100px;
  text-transform: uppercase;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/textbg_shadow_new1.jpg);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  display: block;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.broadcasttrainings_mainwrapper .common_blocktitle {
  background: #f59e83;
  background: linear-gradient(
    180deg,
    rgba(245, 158, 131, 1) 0%,
    rgba(240, 159, 130, 1) 50%,
    rgba(227, 136, 107, 1) 50%,
    rgba(193, 104, 74, 1) 100%
  );
  border-radius: 8px;
  margin: 45px;
  padding: 9px 98px;
}
.broadcasttrainings_mainwrapper .common_blocktitle h3 {
  font-size: 36px;
  color: #411925;
  text-align: center;
}
.broadcasttrainings_mainwrapper .history_walkthrough_wrapper {
  display: flex;
  flex-direction: column;
}
.history_textsection_para {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/text_wrprbg.png)
    no-repeat;
  border-radius: 8px;
  background-position: left -85px top -80px;
  margin-left: -22px;
  padding-top: 20px;
  padding-left: 20px;
}
.broadcasttrainings_mainwrapper .book_history_mainwrapper {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/bg_half_planet.webp)
    no-repeat;
  background-position: left top 200px;
}
.broadcasttrainings_mainwrapper .book_history_subwrapper {
  position: relative;
  border-top: 1px solid #6b4c43;
  border-radius: 7px;
  background: #3a2124;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/videwrpr_bg.webp),
    url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/videotext_bg.webp);
  background-repeat: no-repeat;
  background-position: left bottom -137px, right top;
}
.broadcasttrainings_mainwrapper .book_history_subwrapper::before {
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  right: 0;
  top: 6px;
  background: #000;
  background: linear-gradient(
    180deg,
    #540000 0%,
    rgba(107, 76, 67, 0.7203256302521008) 73%,
    rgba(107, 76, 67, 0) 87%
  );
}
.broadcasttrainings_mainwrapper .book_history_subwrapper::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  left: 0;
  top: 4px;
  background: #7c5d59;
  background: linear-gradient(
    180deg,
    rgba(124, 93, 89, 1) 0%,
    rgba(124, 93, 89, 0.7203256302521008) 44%,
    rgba(124, 93, 89, 0) 100%
  );
}
.broadcasttrainings_mainwrapper .history_walkthrough_textimg {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.broadcasttrainings_mainwrapper .history_walkthrough_textsection {
  margin-left: 45px;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.broadcasttrainings_mainwrapper .amazon_link {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: -25px;
}
.broadcasttrainings_mainwrapper .amazon_link .amazon_link_img {
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 30px;
}
.broadcasttrainings_mainwrapper .amazon_link .amazon_link_img img {
  display: block;
  padding: 21px 32px 7px;
}
.broadcasttrainings_mainwrapper .history_walkthrough_imgsection {
  width: 47%;
  padding-bottom: 294px;
}
.broadcasttrainings_mainwrapper .history_walkthrough_imgsection img {
  max-width: 100%;
}
.broadcasttrainings_mainwrapper .history_textsection h1 {
  margin-bottom: 2px;
}
.broadcasttrainings_mainwrapper .history_textsection h1 span {
  background: #fff;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(186, 186, 186, 0.9640231092436975) 60%,
    rgba(186, 186, 186, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 36px;
}
.broadcasttrainings_mainwrapper .history_textsection h2 {
  margin-bottom: 38px;
}
.broadcasttrainings_mainwrapper .history_textsection h2 span {
  font-size: 36px;
  background: #f49e82;
  background: linear-gradient(
    0deg,
    rgba(244, 158, 130, 1) 0%,
    rgba(244, 158, 130, 1) 50%,
    rgba(191, 103, 81, 1) 50%,
    rgba(191, 103, 81, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.broadcasttrainings_mainwrapper .history_textsection_para p span {
  color: #9996b1;
}
.broadcasttrainings_mainwrapper .history_textsection_para p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 15px;
  line-height: 29px;
}

.broadcasttrainings_mainwrapper .history_textsection_para p:nth-child(5){
  width: 190%;
  margin-bottom: 25px;
}
.broadcasttrainings_mainwrapper .amazon_link_text {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/arrowshadow.png)
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35.5px 69px !important;
  font-size: 39px;
  font-weight: 700;
  color: #3e232d;
  cursor: pointer;
}
.broadcasttrainings_mainwrapper .amazon_link_text .MuiButton-text {
  font-size: 39px;
  font-weight: 700;
  color: #3e232d;
  border: none;
  background: none !important;
  cursor: pointer;
  padding: 0;
}
.broadcasttrainings_mainwrapper .history_walkthrough_video {
  display: flex;
  justify-content: space-between;
  padding: 18px;
  flex-wrap: wrap;
}
.broadcasttrainings_mainwrapper .history_walkthrough_video_wrapper {
  margin: 0 29px;
  position: relative;
  z-index: 9;
}
.broadcasttrainings_mainwrapper
  .book_history_mainwrapper
  .history_walkthrough_video_wrapper {
  background: linear-gradient(
    180deg,
    rgba(49, 19, 30, 1) 0%,
    rgba(51, 23, 24, 1) 50%,
    rgba(15, 6, 13, 1) 100%
  );
  border-radius: 10px;
  margin: 0 29px;
  border-top: 1px solid #6a4454;
  padding-bottom: 0;
  position: relative;
  border-bottom: 1px solid;
  border-image: linear-gradient(
    to right,
    #340000 0%,
    #420000 12%,
    rgba(255, 93, 177, 1) 47%,
    #320000 100%
  );
}
.broadcasttrainings_mainwrapper
  .book_history_mainwrapper
  .history_walkthrough_video_wrapper::before {
  content: "";
  position: absolute;
  height: 95%;
  width: 1px;
  top: 5px;
  left: 0;
  background: #7d3d3a;
  background: linear-gradient(
    180deg,
    rgba(125, 61, 58, 0.5) 0%,
    rgba(125, 61, 58, 0.7091211484593838) 70%,
    rgba(56, 46, 49, 0.37298669467787116) 100%
  );
}
.broadcasttrainings_mainwrapper
  .book_history_mainwrapper
  .history_walkthrough_video_wrapper::after {
  content: "";
  position: absolute;
  height: 95%;
  width: 1px;
  top: 5px;
  right: 0;
  background: #7d3d3a;
  background: linear-gradient(
    180deg,
    rgba(125, 61, 58, 0.5) 0%,
    rgba(125, 61, 58, 0.7091211484593838) 70%,
    rgba(56, 46, 49, 0.37298669467787116) 100%
  );
}
.broadcasttrainings_mainwrapper .walkthrough_video_wrapper1 {
  width: 65%;
  border-top: 1px solid #92706d;
  padding: 15px;
  border-radius: 8px;
  position: relative;
  background: #30262a;
  background: linear-gradient(
    180deg,
    rgba(48, 38, 42, 1) 0%,
    rgba(21, 10, 14, 1) 100%
  );
}
.broadcasttrainings_mainwrapper .walkthrough_video_wrapper1::before {
  background: #7d5a57;
  background: linear-gradient(
    180deg,
    rgba(125, 90, 87, 1) 0%,
    rgba(60, 27, 22, 0.8379726890756303) 71%,
    rgba(60, 27, 22, 0.5) 100%
  );
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  height: 95%;
  width: 1px;
}
.broadcasttrainings_mainwrapper .walkthrough_video_wrapper1::after {
  background: #7d5a57;
  background: linear-gradient(
    180deg,
    rgba(125, 90, 87, 1) 0%,
    rgba(60, 27, 22, 0.8379726890756303) 71%,
    rgba(60, 27, 22, 0.5) 100%
  );
  content: "";
  position: absolute;
  top: 4px;
  right: 0;
  height: 95%;
  width: 1px;
}
.broadcasttrainings_mainwrapper .walkthrough_video_wrapper2 {
  width: 29%;
  display: flex;
  flex-direction: column;
}
.broadcasttrainings_mainwrapper .videowrpper_main {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-top: 0;
  display: block;
}
.broadcasttrainings_mainwrapper .videowrpper_main iframe {
  position: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.broadcasttrainings_mainwrapper .imgvideowrpr1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  cursor: pointer;
}
.broadcasttrainings_mainwrapper .imgvideowrpr1 .imgmainwpr {
  width: 42%;
  border: 2px solid #54443f;
  position: relative;
}
.broadcasttrainings_mainwrapper .imgvideowrpr1 .imgmainwpr .playbuttonlogo {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(41%, -46%);
}
.broadcasttrainings_mainwrapper .imgvideowrpr1 .imgmainwpr .playbuttonlogo img {
  border: none;
}
.broadcasttrainings_mainwrapper .imgvideowrpr1 .imgmainwpr .pausebuttonlogo {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(41%, -46%);
}
.broadcasttrainings_mainwrapper
  .imgvideowrpr1
  .imgmainwpr
  .pausebuttonlogo
  img {
  border: none;
}
.broadcasttrainings_mainwrapper .imgvideowrpr1 .imgmainwpr img {
  border-radius: 3px;
  max-width: 100%;
}
.broadcasttrainings_mainwrapper .imgvideowrpr1 .videotext {
  width: 50%;
}
.broadcasttrainings_mainwrapper .imgvideowrpr1 .videotext h5 {
  font-size: 14px;
  color: #f18f70;
  line-height: 22px;
  margin-bottom: 20px;
}
.broadcasttrainings_mainwrapper .imgvideowrpr1 .videotext p {
  font-size: 12px;
  color: #fff;
}
.broadcasttrainings_mainwrapper .videolibrary_wrpr {
  margin-top: -15px;
}
.broadcasttrainings_mainwrapper .videolibrary_wrpr h6 {
  font-size: 24px;
  color: #dbb3a6;
  margin-right: 15px;
}
.broadcasttrainings_mainwrapper .videolibrary_wrpr .collapse_all {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.broadcasttrainings_mainwrapper .videolibrary_wrpr .expand_all {
  display: flex;
  align-items: center;
}
.broadcasttrainings_mainwrapper .videolibrary_wrpr .MuiButton-text {
  background: none !important;
}
.allvideowrapper .imgvideowrpr1 {
  width: 31.33%;
  padding: 0 0.7%;
}
.allvideowrapper {
  display: flex;
  flex-wrap: wrap;
}
.allvideowrapper.hide {
  display: none;
}
.allvideowrapper.show {
  display: flex;
}
.toggle.hide .collapse_all {
  display: none;
}
.toggle.show .expand_all {
  display: none;
}
.toggle {
  cursor: pointer;
}
.broadcasttrainings_mainwrapper .godsection_mainwrapper {
  margin-top: 50px;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/star_bg.png)
    no-repeat;
  background-position: right bottom;
}
.broadcasttrainings_mainwrapper .godsection_subwrapper {
  border: 1px solid #7b5d59;
  border-bottom: 0;
  border-radius: 7px;
  background: #3a2124;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/gods.webp);
  background-repeat: no-repeat;
  background-position: right -45px top -30px;
  position: relative;
}
.blackbg {
  position: relative;
}
.blackbg::after {
  content: "";
  width: 101%;
  height: 40%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  background: #fff;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0) 49%,
    rgba(0, 0, 0, 1) 100%
  );
}
.broadcasttrainings_mainwrapper .god_details_text {
  padding-right: 51%;
}
.broadcasttrainings_mainwrapper .god_details_textsection {
  margin-left: 45px;
  padding-top: 17px;
}
.broadcasttrainings_mainwrapper .god_details_text h4 {
  font-size: 25px;
  color: #f29070;
  margin-bottom: 40px;
}
.broadcasttrainings_mainwrapper .god_details_text p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 58px;
  line-height: 26px;
}
.broadcasttrainings_mainwrapper .philosophy_fundamental_mainwrapper {
  margin-top: 50px;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/star_bg.png)
    no-repeat;
  background-position: right bottom;
}
.broadcasttrainings_mainwrapper .philosophy_fundamental_subwrapper {
  border: 1px solid #7b5d59;
  border-radius: 7px;
  background: #3a2124;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/fundamentals_bg.webp);
  background-repeat: no-repeat;
  background-position: left -80px top -16px;
  border-bottom: 0;
}
.broadcasttrainings_mainwrapper .fundamental_details_wrapper {
  display: flex;
  flex-direction: column;
}
.broadcasttrainings_mainwrapper .fundamental_details_textimg {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 28px 40px 30px 72px;
}
.broadcasttrainings_mainwrapper .fundamental_text {
  margin-bottom: 35px;
}
.broadcasttrainings_mainwrapper .fundamental_text h4 {
  font-size: 25px;
  color: #f29070;
  margin-bottom: 40px;
}
.broadcasttrainings_mainwrapper .fundamental_text p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 28px;
  line-height: 26px;
}
.broadcasttrainings_mainwrapper .fundamental_textsection {
  width: 55%;
}
.broadcasttrainings_mainwrapper .fundamental_details_imgsection {
  width: 33%;
}
.broadcasttrainings_mainwrapper .fundamental_details_imgsection img {
  max-width: 100%;
}
.broadcasttrainings_mainwrapper .amazon_link_reverse {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.broadcasttrainings_mainwrapper .amazon_link_img_reverse {
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 30px;
}
.broadcasttrainings_mainwrapper .amazon_link_img_reverse img {
  display: block;
  padding: 21px 32px 7px;
}
.broadcasttrainings_mainwrapper .amazon_link_text_reverse {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/arrowshadow_reverse.png)
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35.5px 69px !important;
  font-size: 39px;
  font-weight: 700;
  color: #3e232d;
  cursor: pointer;
}
.broadcasttrainings_mainwrapper .amazon_link_text_reverse .MuiButton-text {
  font-size: 39px;
  font-weight: 700;
  color: #3e232d;
  border: none;
  background: none !important;
  padding: 0;
}
.broadcasttrainings_mainwrapper .world_philosophy_mainwrapper {
  margin-top: 50px;
}
.broadcasttrainings_mainwrapper .world_philosophy_subwrapper {
  border: 1px solid #7b5d59;
  border-radius: 7px;
  background: #3a2124;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/world_phil_bg.webp);
  background-repeat: no-repeat;
  background-position: right -449px top 46px;
  border-bottom: 0;
}
.broadcasttrainings_mainwrapper .world_philosophy_details_wrapper {
  display: flex;
  flex-direction: column;
}
.broadcasttrainings_mainwrapper .world_philosophy_details_textimg {
  display: flex;
  justify-content: space-between;
  margin: 28px 85px 30px 45px;
}
.broadcasttrainings_mainwrapper .world_philosophy_textsection {
  width: 53%;
}
.broadcasttrainings_mainwrapper .world_philosophy_imgsection {
  width: 35%;
}
.broadcasttrainings_mainwrapper .world_philosophy_text {
  font-size: 25px;
}
.broadcasttrainings_mainwrapper .world_philosophy_text h4 {
  color: #fff;
  line-height: 33px;
}
.broadcasttrainings_mainwrapper .world_philosophy_text h5 {
  color: #f29070;
  font-size: 25px;
  line-height: 33px;
  margin-bottom: 36px;
}
.broadcasttrainings_mainwrapper .world_philosophy_text p {
  color: #fff;
  font-size: 18px;
  line-height: 29px;
  margin-bottom: 26px;
}
.world_philosophy_imgsection img {
  max-width: 100%;
}
.broadcasttrainings_mainwrapper .world_philosophy_mainwrapper .amazon_link {
  margin-top: 32px;
}
.broadcasttrainings_mainwrapper .introductory_philosophy_mainwrapper {
  margin-top: 50px;
}
.broadcasttrainings_mainwrapper .introductory_philosophy_subwrapper {
  border: 1px solid #7b5d59;
  border-radius: 7px;
  background: #3a2124;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/introductory_ng.webp);
  background-repeat: no-repeat;
  background-position: left -150px top -49px;
  border-bottom: 0;
}
.broadcasttrainings_mainwrapper .introductory_philosophy_details_wrapper {
  display: flex;
  flex-direction: column;
}
.broadcasttrainings_mainwrapper .introductory_philosophy_details_textimg {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 28px 40px 55px 72px;
}
.broadcasttrainings_mainwrapper .introductory_philosophy_textsection {
  width: 55%;
}
.broadcasttrainings_mainwrapper .introductory_philosophy_text {
  padding-bottom: 5px;
}
.broadcasttrainings_mainwrapper .introductory_philosophy_text h4 {
  font-size: 25px;
  color: #f29070;
  margin-bottom: 41px;
}
.broadcasttrainings_mainwrapper .introductory_philosophy_text p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 28px;
  line-height: 26px;
}
.broadcasttrainings_mainwrapper .introductory_philosophy_imgsection {
  width: 33%;
}
.broadcasttrainings_mainwrapper .introductory_philosophy_imgsection img {
  max-width: 100%;
}
.broadcasttrainings_mainwrapper .religion_philosophy_mainwrapper {
  margin-top: 50px;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/star_bg.png)
    no-repeat;
  background-position: right bottom;
}
.broadcasttrainings_mainwrapper .religion_philosophy_subwrapper {
  border: 1px solid #7b5d59;
  border-radius: 7px;
  background: #3a2124;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/world_phil_bg.webp);
  background-repeat: no-repeat;
  background-position: right -449px top 46px;
  border-bottom: 0;
}
.broadcasttrainings_mainwrapper .religion_philosophy_details_wrapper {
  display: flex;
  flex-direction: column;
}
.broadcasttrainings_mainwrapper .religion_philosophy_details_textimg {
  display: flex;
  justify-content: space-between;
  margin: 28px 85px 30px 45px;
}
.broadcasttrainings_mainwrapper .religion_philosophy_textsection {
  width: 52%;
}
.broadcasttrainings_mainwrapper .religion_philosophy_imgsection {
  width: 40%;
}
.broadcasttrainings_mainwrapper .religion_philosophy_imgsection img {
  max-width: 100%;
}
.broadcasttrainings_mainwrapper .religion_philosophy_text h4 {
  font-size: 25px;
  color: #f29070;
  margin-bottom: 40px;
}
.broadcasttrainings_mainwrapper .religion_philosophy_text p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 30px;
  line-height: 26px;
}
.broadcasttrainings_mainwrapper .religion_philosophy_mainwrapper .amazon_link {
  margin-top: 41px;
}
.broadcasttrainings_mainwrapper .nietzsche_videos_mainwrapper {
  margin-top: 50px;
  padding-bottom: 30px;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/star_bg.png)
    no-repeat;
  background-position: right bottom;
}
.broadcasttrainings_mainwrapper .nietzsche_videos_subwrapper {
  border: 1px solid #7b5d59;
  border-radius: 7px;
  background: #3a2124;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/nietzche_bg.webp);
  background-repeat: no-repeat;
  background-position: left -136px top -71px;
  border-bottom: 0;
}
.broadcasttrainings_mainwrapper .nietzsche_videos_details_wrapper {
  display: flex;
  flex-direction: column;
}
.broadcasttrainings_mainwrapper .nietzsche_videos_details_textimg {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 28px 40px 55px 72px;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Broadcast_page/Nietzsche.webp);
  background-repeat: no-repeat;
  background-position: left top;
  padding-bottom: 100px;
  margin-bottom: -100px;
}
.broadcasttrainings_mainwrapper .nietzsche_videos_textsection {
  width: 55%;
}
.broadcasttrainings_mainwrapper .nietzsche_videos_imgsection {
  width: 40%;
}
.broadcasttrainings_mainwrapper .nietzsche_videos_imgsection img {
  max-width: 100%;
}
.broadcasttrainings_mainwrapper .nietzsche_videos_text h4 {
  font-size: 25px;
  color: #f29070;
  margin-bottom: 40px;
}
.broadcasttrainings_mainwrapper .nietzsche_videos_text p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 28px;
  line-height: 26px;
}
.broadcasttrainings_mainwrapper .allvideowrapper .imgvideowrpr1:nth-child(1) {
  display: none;
}
.broadcasttrainings_mainwrapper .allvideowrapper .imgvideowrpr1:nth-child(2) {
  display: none;
}
.broadcasttrainings_mainwrapper .allvideowrapper .imgvideowrpr1:nth-child(3) {
  display: none;
}

/* <----------------------------Broadcast_page Responsive Start-----------------------------> */

@media (max-width: 1780px) {
  .broadcasttrainings_mainwrapper .broadcast_banner_mainwrapper {
    background-position: left -200px center;
  }
}
@media (max-width: 1750px) {
  .broadcasttrainings_mainwrapper .broadcast_banner_mainwrapper {
    background-position: left -220px center;
  }
}
@media (max-width: 1600px) {
  .broadcasttrainings_mainwrapper .history_textsection h1 span {
    font-size: 32px;
  }
  .broadcasttrainings_mainwrapper .history_textsection h2 span {
    font-size: 32px;
  }
  .broadcasttrainings_mainwrapper .history_textsection_para p {
    margin-bottom: 20px;
    line-height: 25px;
  }
  .broadcasttrainings_mainwrapper .imgvideowrpr1 .imgmainwpr {
    height: fit-content;
  }
  .broadcasttrainings_mainwrapper .imgvideowrpr1 .imgmainwpr img {
    max-height: 100%;
  }
  .broadcasttrainings_mainwrapper .imgvideowrpr1 .videotext h5 {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 13px;
  }
  .broadcasttrainings_mainwrapper .fundamental_text h4 {
    margin-bottom: 40px;
  }
  .broadcasttrainings_mainwrapper .fundamental_text p {
    margin-bottom: 30px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text {
    font-size: 23px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text h5 {
    font-size: 23px;
    margin-bottom: 25px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_mainwrapper .amazon_link {
    margin-top: 25px;
  }
  .broadcasttrainings_mainwrapper .religion_philosophy_text h4 {
    font-size: 23px;
    margin-bottom: 25px;
  }
  .broadcasttrainings_mainwrapper .religion_philosophy_text p {
    font-size: 18px;
    margin-bottom: 22px;
    line-height: 26px;
  }
}
@media (max-width: 1577px) {
  .broadcasttrainings_mainwrapper .god_details_text {
    padding-right: 48%;
  }
  .broadcasttrainings_mainwrapper .book_title h2 span {
    font-size: 75px;
    line-height: 95px;
  }
}
@media (max-width: 1500px) {
  .broadcasttrainings_mainwrapper .godsection_subwrapper {
    background-position: right -87px top -30px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_details_textimg {
    align-items: center;
  }
  .broadcasttrainings_mainwrapper .religion_philosophy_details_textimg {
    align-items: center;
  }
  .broadcasttrainings_mainwrapper .fundamental_details_imgsection {
    width: 40%;
}
}
@media (max-width: 1481px) {
  .broadcasttrainings_mainwrapper .god_details_text {
    padding-right: 53%;
  }
  .broadcasttrainings_mainwrapper .god_details_text h4 {
    font-size: 20px;
    margin-bottom: 40px;
  }
  .broadcasttrainings_mainwrapper .god_details_text p {
    font-size: 18px;
    margin-bottom: 35px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text {
    font-size: 21px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text h5 {
    font-size: 21px;
    margin-bottom: 25px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text p {
    color: #fff;
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 26px;
  }
}
@media (max-width: 1473px) {
  .broadcasttrainings_mainwrapper .book_title h2 span {
    font-size: 65px;
    line-height: 85px;
  }
  .broadcasttrainings_mainwrapper .history_textsection h1 span {
    font-size: 26px;
  }
  .broadcasttrainings_mainwrapper .history_textsection h2 span {
    font-size: 26px;
  }
  .broadcasttrainings_mainwrapper .fundamental_details_textimg {
    margin: 28px 40px 14px 72px;
  }
  .broadcasttrainings_mainwrapper .fundamental_text p {
    margin-bottom: 20px;
  }
}
@media (max-width: 1400px) {
  .broadcasttrainings_mainwrapper .book_history_subwrapper {
    background-size: auto, 100%;
    background-position: left bottom -137px, right top 19%;
  }
  .broadcasttrainings_mainwrapper .religion_philosophy_text h4 {
    font-size: 21px;
    margin-bottom: 23px;
  }
  .broadcasttrainings_mainwrapper .religion_philosophy_text p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 22px;
  }
  .broadcasttrainings_mainwrapper
    .religion_philosophy_mainwrapper
    .amazon_link {
    margin-top: 20px;
  }
  .broadcasttrainings_mainwrapper .introductory_philosophy_imgsection {
    width: 40%;
}
}
@media (max-width: 1393px) {
  .broadcasttrainings_mainwrapper .introductory_philosophy_details_textimg {
    margin: 28px 40px 30px 72px;
  }
  .broadcasttrainings_mainwrapper .introductory_philosophy_text h4 {
    font-size: 23px;
    margin-bottom: 30px;
  }
  .broadcasttrainings_mainwrapper .introductory_philosophy_text p {
    margin-bottom: 25px;
    line-height: 24px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_textsection {
    width: 55%;
  }
  .broadcasttrainings_mainwrapper .religion_philosophy_textsection {
    width: 55%;
  }
}
@media (max-width: 1380px) {
  .broadcasttrainings_mainwrapper .fundamental_text p {
    margin-bottom: 20px;
    font-size: 16px;
  }
  .broadcasttrainings_mainwrapper .fundamental_text h4 {
    margin-bottom: 20px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text {
    font-size: 18px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text h4 {
    line-height: 26px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text h5 {
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 26px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text p {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (max-width: 1316px) {
  .broadcasttrainings_mainwrapper .broadcast_logo_section {
    width: 48%;
    height: 251px;
  }
  .broadcasttrainings_mainwrapper .book_title h2 span {
    font-size: 53px;
    line-height: 72px;
  }
  .broadcasttrainings_mainwrapper .god_details_text {
    padding-right: 58%;
  }
  .broadcasttrainings_mainwrapper .god_details_text h4 {
    font-size: 16px;
    margin-bottom: 25px;
    margin-top: -20px;
  }
  .broadcasttrainings_mainwrapper .god_details_text p {
    font-size: 15px;
  }
}
@media (max-width: 1300px) {
  .broadcasttrainings_mainwrapper .introductory_philosophy_details_textimg {
    align-items: center;
  }
  .broadcasttrainings_mainwrapper .introductory_philosophy_text h4 {
    font-size: 21px;
    margin-bottom: 25px;
  }
  .broadcasttrainings_mainwrapper .introductory_philosophy_text p {
    margin-bottom: 20px;
    line-height: 21px;
    font-size: 16px;
  }
}
@media (max-width: 1288px) {
  .broadcasttrainings_mainwrapper .imgvideowrpr1 .videotext h5 {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 11px;
  }
  .broadcasttrainings_mainwrapper .imgvideowrpr1 .videotext p {
    font-size: 11px;
  }
}
@media (max-width: 1280px) {
  .broadcasttrainings_mainwrapper .broadcast_logo_section {
    width: 44%;
    height: 240px;
  }
  /* .broadcasttrainings_mainwrapper .book_title h2 span{font-size:57px;line-height:72px} */
}
@media (max-width: 1199px) {
  .broadcasttrainings_mainwrapper .broadcast_banner_subwrapper {
    flex-direction: column;
  }
  .broadcasttrainings_mainwrapper .broadcast_banner_mainwrapper {
    background-position: left -73px top;
  }
  .broadcasttrainings_mainwrapper .observable_universe_logo {
    margin-top: 33px;
    margin-left: 0;
    width: auto;
  }
  .broadcasttrainings_mainwrapper .broadcast_logo_section {
    width: 100%;
    height: auto;
  }
  .observable_universe_logo img {
    width: 100%;
  }
  .broadcasttrainings_mainwrapper .broadcast_logo_section {
    border-radius: unset;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 30px;
  }
  .broadcasttrainings_mainwrapper .book_title {
    text-align: center;
    padding: 20px;
  }
  .broadcasttrainings_mainwrapper .book_title h2 span {
    display: inline-block;
  }
  .broadcasttrainings_mainwrapper .broadcast_banner_mainwrapper {
    background-position: left -57px top;
  }
  .broadcasttrainings_mainwrapper .philosophy_fundamental_subwrapper {
    background-position: left 81px top -16px;
  }
  .broadcasttrainings_mainwrapper .history_walkthrough_textimg {
    flex-direction: column-reverse;
  }
  .broadcasttrainings_mainwrapper .book_history_subwrapper {
    background-position: left bottom -137px, right top -2000px;
  }
  .broadcasttrainings_mainwrapper .history_walkthrough_imgsection {
    margin: 0 auto;
    width: auto;
    padding-bottom: 0px;
  }
  .broadcasttrainings_mainwrapper .history_walkthrough_textsection {
    margin: 0 25px;
    width: auto;
  }
  .broadcasttrainings_mainwrapper .amazon_link_text {
    background-image: none;
    background: #e0bbaf;
    background: linear-gradient(
      180deg,
      rgba(224, 187, 175, 1) 0%,
      rgba(213, 170, 156, 1) 50%,
      rgba(206, 155, 139, 1) 50%,
      rgba(192, 135, 117, 1) 100%
    );
    padding: 13px 25px !important;
  }
  .broadcasttrainings_mainwrapper .amazon_link_text .MuiButton-text {
    font-size: 28px;
  }
  .broadcasttrainings_mainwrapper .amazon_link {
    margin-top: 0;
    margin-bottom: 0;
  }
  .broadcasttrainings_mainwrapper .walkthrough_video_wrapper1 {
    width: 100%;
  }
  .broadcasttrainings_mainwrapper .videowrpper_main {
    height: auto;
    padding-top: 56.25%;
  }
  .broadcasttrainings_mainwrapper .videowrpper_main iframe {
    position: absolute;
  }
  .broadcasttrainings_mainwrapper .walkthrough_video_wrapper2 {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: unset;
    margin: 30px 0px;
    justify-content: space-between;
  }
  .broadcasttrainings_mainwrapper .imgvideowrpr1 {
    width: 32.33%;
  }
  .allvideowrapper .imgvideowrpr1 {
    padding: 0 0.5%;
  }
  .broadcasttrainings_mainwrapper .godsection_subwrapper {
    background-image: none;
  }
  .broadcasttrainings_mainwrapper .common_blocktitle {
    margin: 35px;
  }
  .broadcasttrainings_mainwrapper .god_details_text {
    padding-right: 0;
  }
  .broadcasttrainings_mainwrapper .god_details_text h4 {
    margin-bottom: 20px;
  }
  .broadcasttrainings_mainwrapper .god_details_text p {
    margin-bottom: 20px;
  }
  .broadcasttrainings_mainwrapper .fundamental_details_textimg {
    flex-direction: column-reverse;
    margin: 0 25px 30px;
  }
  .broadcasttrainings_mainwrapper .fundamental_textsection {
    width: 100%;
    margin-top: 30px;
  }
  .broadcasttrainings_mainwrapper .amazon_link_text_reverse {
    background-image: none;
    background: #e0bbaf;
    background: linear-gradient(
      180deg,
      rgba(224, 187, 175, 1) 0%,
      rgba(213, 170, 156, 1) 50%,
      rgba(206, 155, 139, 1) 50%,
      rgba(192, 135, 117, 1) 100%
    );
    padding: 13px 25px !important;
  }
  .broadcasttrainings_mainwrapper .amazon_link_text_reverse .MuiButton-text {
    font-size: 28px;
  }
  .broadcasttrainings_mainwrapper .fundamental_details_imgsection {
    width: auto;
    margin: 0 auto;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_details_textimg {
    flex-direction: column-reverse;
    margin: 0 25px 30px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_imgsection {
    width: auto;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_textsection {
    width: 100%;
    margin: 30px 30px 0;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text {
    font-size: 25px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text h4 {
    line-height: 33px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text h5 {
    font-size: 25px;
    margin-bottom: 25px;
    line-height: 33px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_text p {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .broadcasttrainings_mainwrapper .introductory_philosophy_details_textimg {
    flex-direction: column-reverse;
    margin: 0 25px 30px;
  }
  .broadcasttrainings_mainwrapper .introductory_philosophy_textsection {
    width: 100%;
  }
  .broadcasttrainings_mainwrapper .introductory_philosophy_imgsection {
    width: auto;
  }
  .broadcasttrainings_mainwrapper .introductory_philosophy_text {
    margin-top: 30px;
  }
  .broadcasttrainings_mainwrapper .introductory_philosophy_text h4 {
    font-size: 25px;
  }
  .broadcasttrainings_mainwrapper .introductory_philosophy_text p {
    margin-bottom: 20px;
    line-height: 25px;
    font-size: 18px;
  }
  .broadcasttrainings_mainwrapper .religion_philosophy_details_textimg {
    flex-direction: column-reverse;
    margin: 0 25px 30px;
  }
  .broadcasttrainings_mainwrapper .religion_philosophy_textsection {
    width: 100%;
  }
  .broadcasttrainings_mainwrapper .religion_philosophy_imgsection {
    width: auto;
  }
  .broadcasttrainings_mainwrapper .religion_philosophy_text {
    margin-top: 30px;
  }
  .broadcasttrainings_mainwrapper .religion_philosophy_text h4 {
    font-size: 25px;
    margin-bottom: 25px;
  }
  .broadcasttrainings_mainwrapper .religion_philosophy_text p {
    font-size: 18px;
    margin-bottom: 24px;
    line-height: 24px;
  }
  .broadcasttrainings_mainwrapper .nietzsche_videos_subwrapper {
    background-image: none;
  }
  .broadcasttrainings_mainwrapper .nietzsche_videos_details_textimg {
    background-position: center top;
    margin: 0 25px 2px;
    padding-bottom: 0;
    background-size: 83%;
    padding-top: 107%;
  }
  .broadcasttrainings_mainwrapper .nietzsche_videos_textsection {
    width: 100%;
  }
  .broadcasttrainings_mainwrapper .god_details_textsection {
    margin: 25px;
  }
  .broadcasttrainings_mainwrapper .fundamental_text {
    margin-bottom: 25px;
  }
  .broadcasttrainings_mainwrapper .world_philosophy_mainwrapper .amazon_link {
    margin-top: 20px;
  }
  .broadcasttrainings_mainwrapper .history_textsection_para p:nth-child(5) {
    width: 100%;
}
.broadcasttrainings_mainwrapper .book_history_mainwrapper .history_walkthrough_video_wrapper{
  margin-top: 20px;
}
}
@media (max-width: 1050px) {
  .broadcasttrainings_mainwrapper .broadcast_banner_mainwrapper {
    background-position: left -134px top;
  }
}
@media (max-width: 981px) {
  .broadcasttrainings_mainwrapper .imgvideowrpr1 .videotext h5 {
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 7px;
  }
  .broadcasttrainings_mainwrapper .imgvideowrpr1 .videotext p {
    font-size: 10px;
  }
  .broadcasttrainings_mainwrapper .imgvideowrpr1 {
    width: 49%;
  }
  .broadcasttrainings_mainwrapper .history_walkthrough_video {
    padding: 0;
  }
  .broadcasttrainings_mainwrapper .book_history_subwrapper {
    background-position: left bottom -2000px, right top -2000px;
  }
  .broadcasttrainings_mainwrapper
    .book_history_mainwrapper
    .history_walkthrough_video_wrapper {
    background: none;
  }
  .broadcasttrainings_mainwrapper
    .book_history_mainwrapper
    .history_walkthrough_video_wrapper::before {
    display: none;
  }
  .broadcasttrainings_mainwrapper
    .book_history_mainwrapper
    .history_walkthrough_video_wrapper::after {
    display: none;
  }
}
@media (max-width: 870px) {
  .broadcasttrainings_mainwrapper .broadcast_banner_mainwrapper {
    background-position: left -226px top;
  }
  .broadcasttrainings_mainwrapper .nietzsche_videos_text h4 {
    margin-bottom: 30px;
  }
  .broadcasttrainings_mainwrapper .nietzsche_videos_text p {
    margin-bottom: 20px;
  }
  .broadcasttrainings_mainwrapper .philosophy_fundamental_subwrapper {
    background-image: none;
  }
}
@media (max-width: 700px) {
  .broadcasttrainings_mainwrapper .broadcast_banner_mainwrapper {
    background-position: left -312px top;
  }
}
@media (max-width: 680px) {
  .broadcasttrainings_mainwrapper .common_blocktitle {
    margin: 25px;
  }
  .broadcasttrainings_mainwrapper .common_blocktitle h3 {
    font-size: 20px;
  }
  .broadcasttrainings_mainwrapper .imgvideowrpr1 {
    width: 100%;
  }
  .broadcasttrainings_mainwrapper .nietzsche_videos_details_textimg {
    padding-top: 99%;
  }
  .broadcasttrainings_mainwrapper .videolibrary_wrpr h6 {
    font-size: 20px;
  }
}
@media (max-width: 580px) {
  .broadcasttrainings_mainwrapper .book_title h2 span {
    font-size: 47px;
    line-height: 60px;
  }
  .broadcasttrainings_mainwrapper .amazon_link {
    flex-direction: column;
  }
  .broadcasttrainings_mainwrapper .amazon_link .amazon_link_img {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .broadcasttrainings_mainwrapper .amazon_link_reverse {
    flex-direction: column;
  }
  .broadcasttrainings_mainwrapper .amazon_link_img_reverse {
    margin-left: 0;
    margin-bottom: 20px;
  }
}
@media (max-width: 560px) {
  .broadcasttrainings_mainwrapper .broadcast_banner_mainwrapper {
    background-position: left -372px top -53px;
  }
}
@media (max-width: 480px) {
  .broadcasttrainings_mainwrapper .book_title h2 span {
    font-size: 30px;
    line-height: 38px;
  }
  .broadcasttrainings_mainwrapper .broadcast_banner_mainwrapper {
    background: none;
  }
  .broadcasttrainings_mainwrapper .nietzsche_videos_text h4 {
    font-size: 20px;
  }
  .broadcasttrainings_mainwrapper .common_blocktitle {
    padding: 9px 25px;
  }
  .broadcasttrainings_mainwrapper .history_textsection h1 span,
  .broadcasttrainings_mainwrapper .history_textsection h2 span {
    font-size: 22px;
  }
}

/* <----------------------------Broadcast_page Responsive End-----------------------------> */

/* --------------------------------- Broadcast Training Css End ---------------------------- */

/* --------------------------------- Blog Css Start ---------------------------- */
.blog_mainwrapper {
  background-color: #000;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Blog_page/blog_main_bg.webp);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100% 100%;
}
.blog_mainwrapper .book_section_wrapper {
  display: flex;
  flex-wrap: nowrap;
}
.blog_mainwrapper .book_section {
  z-index: 3;
  width: 44%;
}
.blog_mainwrapper .book_section img {
  margin-left: -7px;
  margin-top: 12px;
}
.blog_mainwrapper .blog_banner_mainsection {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Blog_page/banner-left-bg.webp),
    url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Blog_page/banner_right_planet.webp);
  background-repeat: no-repeat, no-repeat;
  background-position: top -24% left -10%, bottom -41% right -12%;
  background-size: auto, auto;
}
.blog_mainwrapper .book_details {
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Blog_page/banner_grey_bg.webp);
  background-repeat: no-repeat;
  border: 1px solid #c7917f;
  width: 55.5%;
  height: 285px;
  margin-top: 155px;
  margin-left: -50px;
  border-radius: 35px;
  background-size: cover;
  z-index: 1;
}
.blog_mainwrapper .book_title h2 span {
  font-size: 83px;
  line-height: 99px;
  text-transform: uppercase;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/textbg_shadow_new1.jpg);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.blog_mainwrapper .book_details_wrapper {
  padding: 43px 20px 63px 72px;
}
.blog_mainwrapper .blog_subwrapper {
  display: flex;
  flex-direction: column;
}
.blog_mainwrapper .blog_subwrapper .blog_title h3 {
  font-size: 36px;
  color: #fff;
  text-transform: uppercase;
}
.blog_mainwrapper .blog_subwrapper .blog_title h3 span {
  color: #f29070;
}
.blog_mainwrapper .blog_title {
  background: #30262a;
  background: linear-gradient(
    90deg,
    rgba(48, 38, 42, 1) 0%,
    rgba(20, 9, 13, 1) 67%,
    rgba(20, 9, 13, 1) 100%
  );
  border-radius: 5px;
  border: 1px solid #4a342a;
  padding: 44px;
  margin-bottom: 48px;
}
.blog_mainwrapper .blog_block_mainwrpr {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.4%;
}
.blog_mainwrapper .blog_block_mainwrpr .blog1 {
  color: #fff;
  flex: 1 0 46%;
  margin: 1.4%;
  background-color: #34282b;
  border-radius: 5px;
}
.blog_mainwrapper .blog_block_mainwrpr .blog1 .blog1_wrppr {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #735c56;
  border-radius: 5px;
  height: 96.5%;
}
.blog_mainwrapper .blog_image_block {
  background: #71797d;
  background: linear-gradient(
    180deg,
    rgba(113, 121, 125, 1) 0%,
    rgba(60, 68, 73, 1) 100%
  );
  padding: 20px 10px 4px;
  border: 1px solid #c79f93;
  border-radius: 3px;
  margin-bottom: 10px;
  /* padding-bottom: 0px; */
}
.blog_mainwrapper .blog_img_wrpr {
  overflow: hidden;
  display: block;
  position: relative;
  margin-bottom: 0px;
}

.blog_mainwrapper .blog_video_wrpr {
  padding-top: 56.25%;
  display: block;
  position: relative;
}

.blog_mainwrapper .blog_video_wrpr iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.blog_mainwrapper .blog_img_wrpr span {
  display: block;
  height: 340px;
  overflow: hidden;
}
.blog_mainwrapper .blog_img_wrpr img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
}
.blog_mainwrapper .blog_text_block {
  background: url(https://all-frontend-assets.s3.amazonaws.com/timothydesmond-assets/Blog_page/blog_text-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #7c6158;
  border-radius: 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blog_mainwrapper .blog_txt_wrpr {
  padding: 17px;
}
.blog_mainwrapper .blog_txt_wrpr h5 {
  font-size: 24px;
  color: #f99f82;
  position: relative;
  padding-left: 10px;
  margin-bottom: 9px;
  text-transform: uppercase;
}
.blog_mainwrapper .blog_txt_wrpr h5::before {
  content: "";
  height: 100%;
  width: 3px;
  position: absolute;
  left: 1px;
  top: 2px;
  background: #fff;
  display: block;
}
.blog_mainwrapper .blog_txt_wrpr p {
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  /* overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-line-clamp:4;
  -webkit-box-orient:vertical */
}
.blog_mainwrapper .blog_txt_wrpr p span {
  font-size: 18px !important;
  line-height: 25px !important;
  color: #fff !important;
}
.blog_mainwrapper .blog_para_wrpr {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.blog_mainwrapper .blog_txt_wrpr a {
  text-decoration: none;
  cursor: pointer;
}
.blog_mainwrapper .blog_txt_wrpr a span {
  color: #f30102 !important;
  font-size: 18px;
  font-weight: 700;
}
.blog_mainwrapper .date_top {
  position: absolute;
  top: 0;
  left: 0;
  width: 130px;
  background: #dc0013;
  text-align: center;
  font-size: 18px;
  padding: 5px;
}
.blog_mainwrapper .blog_img_footer {
  position: absolute;
  bottom: 0;
  width: auto;
}
.blog_mainwrapper .blog_img_footer_wrpr {
  display: flex;
  justify-content: space-between;
}
.blog_mainwrapper .socialmedia {
  display: flex;
  justify-content: space-between;
  background: #02161b;
  padding: 7px 12px;
  margin-left: 1px;
  width: 120px;
  align-items: center;
  cursor: pointer;
}
.blog_mainwrapper .blogauthor {
  background: #e0bbaf !important;
  background: linear-gradient(
    180deg,
    rgba(224, 187, 175, 1) 0%,
    rgba(213, 170, 156, 1) 50%,
    rgba(206, 155, 139, 1) 50%,
    rgba(192, 135, 117, 1) 100%
  ) !important;
  margin-right: 1px;
  padding: 12px;
  width: 62%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.blog_mainwrapper .blogauthor h6 {
  font-size: 24px;
  text-transform: uppercase;
  color: #000;
  font-weight: 700;
}
.blog_mainwrapper .blogauthor p {
  color: #c10214;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
}
.blog_mainwrapper .blog_img_wrpr .socialmedia img {
  width: auto;
  height: auto;
}

.LinearProgress_div {
  display: block;
  width: 100%;
}

.LinearProgress_div .css-eglki6-MuiLinearProgress-root {
  height: 6px;
  z-index: 0;
  background-color: rgb(225 96 56);
  margin-bottom: 65px;
}

.LinearProgress_div .css-5ir5xx-MuiLinearProgress-bar1 {
  background-color: #444444;
}

.LinearProgress_div .css-1r8wrcl-MuiLinearProgress-bar2 {
  background-color: #444444;
}

.rightmenu_wrpr {
  justify-content: flex-end;
}
.blogdetail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 25px;
}
.blogdetail .blog1 {
  color: #fff !important;
  flex: inherit !important;
  margin: 0 !important;
  background: none !important;
  border-radius: 5px !important;
  display: block !important;
  width: 68% !important;
}

.blog_right {
  border: 1px solid #735c56;
  border-radius: 5px;
  width: 30%;
  background-color: #34282b;
}

.blog_right_wrapper {
  padding: 10px;
}
.blog_right_con {
  background: #30262a;
  background: linear-gradient(
    90deg,
    rgba(48, 38, 42, 1) 0%,
    rgba(20, 9, 13, 1) 67%,
    rgba(20, 9, 13, 1) 100%
  );
  border-radius: 5px;
  border: 1px solid #4a342a;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
}

.blog_right_body {
  margin: 10px 0;
}
.blog_right_body:first-of-type {
  margin-top: 0;
}

.blog_right_body:last-of-type {
  margin-bottom: 0;
}

.blog_right_img {
  width: 20%;
  height: 50px;
  overflow: hidden;
  border: 1px solid #4a342a;
}

.blog_right_img img {
  width: 100%;
  min-height: 100%;
  object-fit: contain;
  height: 100%;
}

.blog_right_text {
  width: 75%;
}

.blog_right_text h5 {
  padding: 0;
  color: #fff;
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.blog_right_text {
  width: 75%;
}

.blog_right_text h5 {
  padding: 0;
  color: #fff;
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.blog_right_text span {
  font-size: 14px;
  color: #fff;
}

.blog_right_body .activeblog .blog_right_con {
  background: #71797d;
  background: linear-gradient(
    180deg,
    rgba(113, 121, 125, 1) 0%,
    rgba(60, 68, 73, 1) 100%
  );
  padding: 10px;
  border: 1px solid #c79f93;
}

.blog_mainwrapper .blog_subwrapper button {
  width: fit-content;
  display: block;
  margin: 20px auto;
  font-weight: 600 !important;
  border-radius: 8px !important;
  background: #e0bbaf !important;
  background: linear-gradient(
    180deg,
    rgba(224, 187, 175, 1) 0%,
    rgba(213, 170, 156, 1) 50%,
    rgba(206, 155, 139, 1) 50%,
    rgba(192, 135, 117, 1) 100%
  ) !important;
  cursor: pointer !important;
  text-transform: none !important;
  font-size: 22px;
  border: none;
  padding: 15px;
}
.blogDetails {
  padding-bottom: 20px;
}
.blogDetails .blog_block_mainwrpr .blog1 .blog1_wrppr {
  height: auto;
}

.blogDetails .blogdetail {
  margin: 0% !important;
}
.blogDetails .blog_txt_wrpr{
  padding-bottom: 0px;
}
.blog_mainwrapper .socialmedia button{
background: none !important;
margin: 0 auto;
}

.blog_mainwrapper .blog_bottom{
  margin: 0 0 0 auto;
  background: none;
}


/* <----------------------------Blog_page Responsive Start-----------------------------> */

@media (max-width: 1773px) {
  .blog_mainwrapper .blog_banner_mainsection {
    background-position: top -24% left -17%, bottom -41% right -12%;
  }
}
@media (max-width: 1700px) {
  .blog_mainwrapper .book_section img {
    margin-left: 64px;
  }
  .blog_mainwrapper .book_details {
    width: 49.5%;
    margin-left: -13px;
    height: 260px;
  }
  .blog_mainwrapper .book_title h2 span {
    font-size: 70px;
    line-height: 85px;
  }
  .blog_mainwrapper .book_title {
    padding-left: 25px;
  }
}
@media (max-width: 1665px) {
  /* .blog_mainwrapper .book_title h2 span{font-size:75px} */
  /* .blog_mainwrapper .book_details_wrapper{padding:43px 20px 63px 110px} */
  /* .blog_mainwrapper .book_details{height:265px} */
}
@media (max-width: 1565px) {
  /* .blog_mainwrapper .book_title h2 span{font-size:75px} */
  .blog_mainwrapper .book_details_wrapper {
    padding: 43px 20px 63px 60px;
  }
  .blog_mainwrapper .book_section img {
    width: 100%;
    margin-left: 93px;
  }
  .blog_mainwrapper .book_details {
    margin-top: 128px;
    height: 254px;
  }

  .header_mainwrapper .navbar {
    width: 78%;
    position: relative;
  }
}
@media (max-width: 1520px) {
  .blog_mainwrapper .book_title h2 span {
    font-size: 60px;
    line-height: 80px;
  }
}
@media (max-width: 1445px) {
  /* .blog_mainwrapper .book_title h2 span{font-size:65px} */
  .blog_mainwrapper .book_details_wrapper {
    padding: 36px 20px 63px 60px;
  }
  .blog_mainwrapper .book_details {
    height: 231px;
  }
  .blog_mainwrapper .blog_banner_mainsection {
    background-position: top 46% left -28%, bottom -41% right -12%;
  }
}
@media (max-width: 1337px) {
  .blog_mainwrapper .book_title {
    padding-left: 10px;
  }
  .blog_mainwrapper .book_title h2 span {
    font-size: 55px;
    line-height: 70px;
  }
}
@media (max-width: 1318px) {
  .blog_mainwrapper .blog_txt_wrpr p {
    font-size: 16px;
  }
}
@media (max-width: 1300px) {
  .blog_mainwrapper .blog_img_footer_wrpr {
    flex-direction: column-reverse;
  }
  /* .blog_mainwrapper .socialmedia{margin:10px auto 0;width:42%} */
  .blog_mainwrapper .blogauthor {
    margin: 0 auto;
    width: 93%;
  }
}
@media (max-width: 1276px) {
  .blog_mainwrapper .book_title h2 span {
    font-size: 40px;
    line-height: 60px;
  }
  .blog_mainwrapper .book_details {
    height: 195px;
    width: 43.5%;
  }
}
@media (max-width: 1199px) {
  .blog_mainwrapper .blog_txt_wrpr p {
    -webkit-line-clamp: 5;
  }
  .blog_mainwrapper .book_section_wrapper {
    flex-direction: column;
  }
  .blog_mainwrapper .book_section {
    width: 100%;
  }
  .blog_mainwrapper .book_section img {
    width: 50%;
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
  .blog_mainwrapper .book_details {
    margin-top: -9px;
    width: 100%;
    height: auto;
    margin-left: 0;
    border-radius: 0;
    margin-bottom: 30px;
  }
  .blog_mainwrapper .book_details_wrapper {
    padding: 20px;
  }
  .blog_mainwrapper .book_title {
    text-align: center;
  }
  .blog_mainwrapper .book_title h2 span {
    display: inline-block !important;
    font-size: 40px;
  }
  .blog_mainwrapper .blog_title {
    margin: 30px 0;
    padding: 20px;
  }
  .blog_mainwrapper .blog_subwrapper .blog_title h3 {
    font-size: 25px;
    line-height: 35px;
  }
  .blog_mainwrapper .blog_banner_mainsection {
    background-position: top 221% left -43%, bottom -41% right -12%;
  }
}
@media (max-width: 991px) {
  .blogdetail {
    display: block;
  }
  .blogdetail .blog1 {
    width: auto !important;
  }

  .blog_right {
    width: auto !important;
    margin-top: 15px;
  }

  .blog_mainwrapper .blog_block_mainwrpr .blog1 {
    flex: 1 0 50%;
    max-width: 100% !important;
  }
  .blog_mainwrapper .blog_txt_wrpr p {
    -webkit-line-clamp: 4;
  }
  /* .blog_mainwrapper .socialmedia{margin:0 auto} */
  .blog_mainwrapper .blog_banner_mainsection {
    background-position: top 73% left -115%, bottom -41% right -62%;
  }

  .blog_mainwrapper .blog_img_wrpr span {
    height: 270px;
  }
}
@media (max-width: 850px) {
  .blog_mainwrapper .book_section img {
    margin: 22px auto 0;
  }
  .blog_mainwrapper .blog_banner_mainsection {
    background: none;
  }
}
@media (max-width: 580px) {
  .blog_mainwrapper .book_title h2 span {
    font-size: 30px;
  }
  .blog_mainwrapper .book_details_wrapper {
    padding: 10px;
  }
  .blog_mainwrapper .blog_title {
    padding: 10px;
  }
  .blog_mainwrapper .blog_subwrapper .blog_title h3 {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (max-width: 480px) {
  .blog_mainwrapper .blogauthor {
    flex-direction: column;
    padding: 8px;
  }
  .blog_mainwrapper .date_top img {
    width: 50%;
  }
  .blog_mainwrapper .blogauthor h6 {
    font-size: 18px;
  }
  .blog_mainwrapper .blogauthor p {
    font-size: 16px;
  }
  .blog_mainwrapper .book_title h2 span {
    font-size: 22px;
  }
}
/* <----------------------------Blog_page Responsive End-----------------------------> */

/* --------------------------------- Blog Css End ---------------------------- */

/* --------------------------------- Contact Css Start ---------------------------- */

.contact_mainwrapper {
  background-color: #000;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Contact_page/main_bg.jpg);
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
}
.contact_mainwrapper .contact_page_mainsection {
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/home_banner_bg.webp)
    no-repeat;
  background-size: cover;
  background-position: left center;
  padding-top: 117px;
  margin-top: -118px;
}
.contact_mainwrapper .contact_wrapper {
  position: relative;
}
.contact_mainwrapper .contact_wrapper .contact_bg {
  position: absolute;
  z-index: 1;
  top: 80px;
  left: 0;
}
.contact_mainwrapper .book_section_wrapper {
  display: flex;
  flex-wrap: nowrap;
}
.contact_mainwrapper .book_section {
  z-index: 3;
  width: 51%;
}
.contact_mainwrapper .book_section img {
  margin-left: 130px;
}
.contact_mainwrapper .book_details {
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/link_bg1.webp);
  background-repeat: no-repeat;
  border: 1px solid #c7917f;
  width: 46.5%;
  height: 611px;
  margin-top: 95px;
  border-radius: 25px;
  background-size: cover;
  z-index: 1;
}
.contact_mainwrapper .book_details_wrapper {
  margin: 30px 0 33px 130px;
  display: flex;
  flex-direction: column;
  z-index: 4;
  position: relative;
}
.contact_mainwrapper .book_title {
  margin-bottom: 36px;
}
.contact_mainwrapper .book_title h2 span {
  font-size: 51px;
  line-height: 64px;
  text-transform: uppercase;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/textbg_shadow_new1.jpg);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.contact_mainwrapper .socialwrapper_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.contact_mainwrapper .youtube_link {
  background: #e72323;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  font-size: 26px;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 46px;
  cursor: pointer;
}
.contact_mainwrapper .youtube_link a {
  display: flex;
  text-decoration: none;
}
.contact_mainwrapper .youtube_link h4 {
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  width: 100%;
  padding: 0 12px 0 0;
}
.contact_mainwrapper .youtube_link img {
  width: 19%;
}
.contact_mainwrapper .amazon_link {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.contact_mainwrapper .amazon_link .amazon_link_img {
  background: #fff;
  border-radius: 8px;
  margin: 0 16px 0 0;
  cursor: pointer;
}
.contact_mainwrapper .amazon_link .amazon_link_img img {
  padding: 9px 23px;
  display: block;
  cursor: pointer;
}
.contact_mainwrapper .amazon_link .amazon_link_text {
  margin: 0 0 0 16px;
}
.contact_mainwrapper .amazon_link .amazon_link_text h3 {
  font-size: 32px;
  color: #fff;
  font-weight: 700;
}
.contact_mainwrapper .contact_form_mainwrapper {
  padding-bottom: 30px;
}
.contact_mainwrapper .contactform_subwrpr {
  display: flex;
  justify-content: space-between;
  border: 1px solid #61493f;
  border-radius: 5px;
  padding: 55px 100px 45px 45px;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Contact_page/galaxy.webp),
    url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Contact_page/lastsectionbg.webp);
  background-repeat: no-repeat, no-repeat;
  background-position: left bottom 33px, left bottom;
  background-size: auto, cover;
}
.contact_mainwrapper .tod_desmond_img {
  padding: 8px;
  position: relative;
  border: 1px solid #fff;
  border-radius: 8px;
  background-size: 100% 100%;
  margin-top: -102px;
  background-image: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Contact_page/img_bg_layer1.png);
  background-repeat: no-repeat;
}
.contact_mainwrapper .universelogo {
  margin-top: 50px;
}
.contact_mainwrapper .tod_desmondmain {
  display: block;
  max-width: 100%;
}
.contact_mainwrapper .contactus_part h1 span {
  font-size: 68px;
  line-height: 64px;
  background: url(https://d37pbainmkhx6o.cloudfront.net/timothydesmond-assets/Contact_page/textbg_shadow_Contact.png);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: block;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 40px;
}
.contact_mainwrapper .contactus_part p {
  font-size: 18px;
  color: #f0f0f0;
  line-height: 29px;
}
.contact_mainwrapper .contactus_part p span {
  color: #b16953;
}
.contact_mainwrapper .filloutform_part h3 {
  color: #fff;
  font-size: 36px;
  text-transform: uppercase;
  margin-bottom: 28px;
}
.contact_mainwrapper .forminfo p {
  font-size: 18px;
  color: #f0f0f0;
}
.contact_mainwrapper .logo {
  background: #dfb5a8;
  background: linear-gradient(
    180deg,
    rgba(223, 181, 168, 1) 0%,
    rgba(191, 134, 115, 1) 100%
  );
  border-radius: 5px;
  width: 7%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact_mainwrapper .writerLogo_section {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact_mainwrapper .formtext_section {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.contact_mainwrapper .contactus_part {
  margin-bottom: 40px;
}
.contact_mainwrapper .filloutform_part {
  display: flex;
  flex-direction: column;
}
.contact_mainwrapper .forminfo_details {
  margin-bottom: 28px;
  display: flex;
  align-items: center;
}
.contact_mainwrapper .forminput_section {
  width: 38%;
}
.contact_mainwrapper .address_detail {
  padding-left: 30px;
}
.contact_mainwrapper .contact_form .formInline .formdiv {
  margin-bottom: 25px;
  width: 100%;
}
.contact_mainwrapper .contact_form .formInline .formdiv Label {
  color: #b39189;
  font-size: 14px;
  width: 100%;
}
.contact_mainwrapper .contact_form .formInline .formdiv input {
  background: #39282c;
  height: 20px;
  border-radius: 5px;
  font-size: 16px !important;
  -webkit-text-fill-color: #e1cfca !important; color: #e1cfca !important; 
}
.contact_mainwrapper .contact_form .formInline .message input {
  height: 90px !important;
}
.contact_mainwrapper
  .contact_form
  .formInline
  .formdiv
  .MuiOutlinedInput-notchedOutline {
  border-color: #403033;
  border-radius: 5px;
  color: #b39189;
}
.contact_mainwrapper .contact_form .formInline .formdiv .MuiFormLabel-root {
  color: #b39189;
  font-size: 14px;
  padding-left: 0px;
}
.contact_mainwrapper .contact_form .formInline .message .MuiFormLabel-root {
  padding-left: 0px;
  padding-bottom: 2px;
}
.contact_mainwrapper .css-1in441m > span {
  display: none !important;
}
.contact_mainwrapper .contact_form .formInline .formdiv textarea {
  font-size: 16px;
  width: 96% !important;
  height: 90px !important;
  background-color: #39282c;
  resize: none;
  border: solid 1px #403033 !important;
  color: #e1cfca;
  padding: 10px 14px;
  border-radius: 5px;
}
.contact_mainwrapper .submitbtnsection .MuiButton-root {
  font-size: 22px !important;
  color: #110c0b !important;
  padding: 21px 64px;
}
.contact_mainwrapper .submitbtnsection .MuiIconButton-root {
  display: none;
}
.contact_mainwrapper .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  display: none;
}
.contact_mainwrapper .MuiFormControl-root {
  width: 100%;
}
.contact_mainwrapper .formdiv .MuiFormHelperText-root.Mui-error {
  position: absolute;
  bottom: -24px;
  left: 0;
  margin: 0;
  color: #ff0000;
}
/* .contact_mainwrapper .contact_form .formInline .message{margin-bottom:59px} */
.contact_mainwrapper
  .contact_form
  .formInline
  .message
  .MuiFormHelperText-root {
  position: absolute;
  bottom: -24px;
  left: 0;
  margin: 0;
  color: #ff0000;
}
.contact_mainwrapper .contact_form .MuiBox-root {
  display: none;
}
.contact_mainwrapper .contact_form .formInline .formdiv textarea:focus-visible {
  border: solid 1px #403033 !important;
  outline: none !important;
}

.contact_mainwrapper .submitbtnsection .MuiButton-root {
  margin-bottom: -10px;
}

.error {
  color: #ff0000;
  margin-top: 5px;
}

.contact_mainwrapper .contact_form .formInline {
  justify-content: space-between;
}
.contact_mainwrapper .forminfo_details .address_detail a {
  font-size: 18px !important;
  color: #f0f0f0 !important;
  text-decoration: none !important;
}

.contact_mainwrapper input:-webkit-autofill {
  color: #b39189 !important;
  font-size: 14px;
  -webkit-text-fill-color: #b39189 !important;
  -webkit-box-shadow: none !important;
}

.css-cpgvjg-MuiSnackbar-root {
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, -50%) !important;
}

.MuiLinearProgress-root {
  background-color: #36213b !important;
  height: 10px !important;
  margin-top: 10px;
}



/* after deployment it's css not getting */

.css-5ir5xx-MuiLinearProgress-bar1, .MuiLinearProgress-bar, .css-1r8wrcl-MuiLinearProgress-bar2  {
  background-color: #e16038 !important;
}


/* <----------------------------Contact_page Responsive Start-----------------------------> */

@media (max-width: 1500px) {
  .contact_mainwrapper .contact_wrapper .contact_bg {
    top: 97px;
    left: 50px;
  }
  .contact_mainwrapper .contact_wrapper .contact_bg img {
    width: 80%;
  }
  .contact_mainwrapper .book_section img {
    width: 90%;
  }
  .contact_mainwrapper .book_details {
    width: 45.5%;
    height: 512px;
    margin-left: -83px;
  }
  .contact_mainwrapper .book_title h2 span {
    font-size: 45px;
    line-height: 45px;
  }
  .contact_mainwrapper .amazon_link .amazon_link_text h3 {
    font-size: 25px;
  }
}
@media (max-width: 1450px) {
  .contact_mainwrapper .contact_wrapper .contact_bg {
    top: 95px;
    left: 65px;
  }
  .contact_mainwrapper .contact_wrapper .contact_bg img {
    width: 70%;
  }
}
@media (max-width: 1407px) {
  .contact_mainwrapper .contactus_part h1 span {
    font-size: 55px;
    line-height: 55px;
    margin-bottom: 30px;
  }
  .contact_mainwrapper .contactus_part {
    margin-bottom: 30px;
  }
  .contact_mainwrapper .filloutform_part h3 {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .contact_mainwrapper .forminfo p {
    font-size: 16px;
  }
  .contact_mainwrapper .forminfo_details {
    margin-bottom: 45px;
  }
  .contact_mainwrapper .contact_form .formInline .formdiv {
    margin-bottom: 18px;
  }
  .contact_mainwrapper .contact_form .formInline .message {
    margin-bottom: 25px;
  }
}
@media (max-width: 1400px) {
  .contact_mainwrapper .book_details {
    width: 44.5%;
    height: 450px;
    margin-left: -85px;
  }
  .contact_mainwrapper .book_title {
    margin-bottom: 22px;
  }
  .contact_mainwrapper .youtube_link {
    margin-bottom: 26px;
  }
  .contact_mainwrapper .book_title h2 span {
    font-size: 40px;
    line-height: 42px;
  }
  .contact_mainwrapper .amazon_link {
    align-items: stretch;
  }
  .contact_mainwrapper .amazon_link .amazon_link_text h3 {
    font-size: 20px;
  }
  .contact_mainwrapper .amazon_link .amazon_link_img img {
    width: 70%;
  }

  .contact_mainwrapper .contactform_subwrpr {
    padding: 55px 30px 45px 45px;
  }
}
@media (max-width: 1313px) {
  .contact_mainwrapper .contact_wrapper .contact_bg {
    top: 85px;
  }
  .contact_mainwrapper .contact_wrapper .contact_bg img {
    width: 64%;
  }
  .contact_mainwrapper .book_details {
    height: 392px;
  }
  .contact_mainwrapper .book_title h2 span {
    font-size: 35px;
    line-height: 35px;
  }
  .contact_mainwrapper .contactus_part p {
    font-size: 16px;
    color: #f0f0f0;
    line-height: 26px;
  }
  .contact_mainwrapper .formtext_section {
    width: 28%;
  }
  .contact_mainwrapper .contactus_part p {
    font-size: 16px;
  }
  .contact_mainwrapper .address_detail {
    padding-left: 30px;
  }

  .contact_mainwrapper .forminput_section {
    width: 42%;
  }
}
@media (max-width: 1240px) {
  .contact_mainwrapper .forminfo p {
    font-size: 14px;
  }
  .contact_mainwrapper .contactus_part h1 span {
    font-size: 45px;
  }
  .contact_mainwrapper .contactus_part p {
    font-size: 14px;
  }
  .contact_mainwrapper .formtext_section {
    width: 24%;
  }
  .contact_mainwrapper .filloutform_part h3 {
    font-size: 24px;
  }
  .contact_mainwrapper .address_detail {
    padding-left: 14px;
  }
}
@media (max-width: 1199px) {
  .contact_mainwrapper .contact_page_mainsection {
    background-size: contain;
    background-position: top center;
  }
  .contact_mainwrapper .contact_wrapper .contact_bg {
    left: 197px;
    top: 32px;
  }
  .contact_mainwrapper .contact_wrapper .contact_bg img {
    width: 68%;
  }
  .contact_mainwrapper .book_section_wrapper {
    flex-direction: column;
  }
  .contact_mainwrapper .book_section {
    width: 100%;
  }
  .contact_mainwrapper .book_section img {
    width: 50%;
    display: block;
    margin: 0 auto;
  }
  .contact_mainwrapper .book_details {
    margin: -10px auto 0;
    width: 100%;
    height: auto;
  }
  .contact_mainwrapper .book_title h2 span {
    display: inline-block;
    font-size: 30px;
  }
  .contact_mainwrapper .book_details_wrapper {
    margin: 20px;
  }
  .contact_mainwrapper .contact_form_mainwrapper {
    margin-top: 45px;
  }
  .contact_mainwrapper .youtube_link {
    margin: 0 auto;
    padding: 10px 22px;
    font-size: 20px;
  }
  .contact_mainwrapper .book_title {
    text-align: center;
  }
  .contact_mainwrapper .amazon_link {
    margin: 0 auto;
  }
  .contact_mainwrapper .amazon_link .amazon_link_img {
    margin: 0 auto;
  }
  .contact_mainwrapper .contactform_subwrpr {
    flex-direction: column;
    padding: 25px;
  }
  .contact_mainwrapper .writerLogo_section {
    width: 100%;
  }
  .contact_mainwrapper .universelogo {
    display: none;
  }
  .contact_mainwrapper .formtext_section {
    width: 100%;
    margin-top: 25px;
  }
  .contact_mainwrapper .contactus_part h1 span {
    font-size: 50px;
  }
  .contact_mainwrapper .contactus_part p {
    font-size: 18px;
  }
  .contact_mainwrapper .contactus_part p br {
    display: none;
  }
  .contact_mainwrapper .filloutform_part h3 {
    font-size: 35px;
  }
  .contact_mainwrapper .forminfo {
    display: flex;
    justify-content: space-between;
  }
  .contact_mainwrapper .forminfo p {
    font-size: 18px;
  }
  .contact_mainwrapper .forminput_section {
    width: 100%;
  }
  .contact_mainwrapper .tod_desmond_img {
    margin-top: 0;
  }
  .contact_mainwrapper .contact_form .formInline .formdiv textarea {
    width: 97% !important;
  }
  .contact_mainwrapper .forminfo_details {
    margin-bottom: 25px;
  }
}
@media (max-width: 1024px) {
  .contact_mainwrapper .contact_wrapper .contact_bg {
    left: 155px;
  }
  .contact_mainwrapper .contact_wrapper .contact_bg img {
    width: 60%;
  }

  .contact_mainwrapper .forminfo_details:nth-of-type(2) {
    margin-left: 20px;
    margin-right: 20px;
  }
  .contact_mainwrapper .forminfo p {
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .contact_mainwrapper .forminfo {
    flex-direction: column;
    margin: 0 auto;
  }
  .contact_mainwrapper .contact_wrapper .contact_bg img {
    width: 58%;
}
  .contact_mainwrapper .forminfo_details:nth-of-type(2) {
    margin-left: 0;
    margin-right: 0;
  }
  .contact_mainwrapper .forminfo p {
    font-size: 18px;
  }

  .contact_mainwrapper .filloutform_part h3 {
    text-align: center;
  }

  .contact_mainwrapper .contact_form .formInline .message {
    margin-bottom: 25px;
    width: 100%;
  }
  .contact_mainwrapper .contact_wrapper .contact_bg {
    left: 157px;
  }
  .contact_mainwrapper .book_title h2 span {
    font-size: 20px;
    line-height: 25px;
  }
  .contact_mainwrapper .forminfo p {
    font-size: 16px;
  }
  .contact_mainwrapper .contact_form .formInline .formdiv textarea {
    width: 96.5% !important;
  }
}
@media (max-width: 891px) {
  .contact_mainwrapper .contact_wrapper .contact_bg img {
    width: 56%;
  }
  .contact_mainwrapper .contact_wrapper .contact_bg {
    left: 154px;
  }
  .contact_mainwrapper .youtube_link {
    margin: 0 auto 25px;
  }
  .contact_mainwrapper .amazon_link {
    align-items: center;
  }
  .contact_mainwrapper .socialwrapper_section {
    flex-direction: column;
  }
  .contact_mainwrapper .filloutform_part {
    margin: 0 auto;
  }
  .contact_mainwrapper .forminfo {
    flex-direction: column;
  }
}
@media (max-width: 815px) {
  .contact_mainwrapper .contact_page_mainsection {
    padding-top: 150px;
    background: none;
  }
}
@media (max-width: 681px) {
  .contact_mainwrapper .contact_wrapper .contact_bg {
    left: 111px;
  }
  .contact_mainwrapper .contact_wrapper .contact_bg img {
    width: 50%;
}
  .contact_mainwrapper .amazon_link {
    flex-direction: column;
  }
  .contact_mainwrapper .amazon_link .amazon_link_text {
    margin: 25px 0 0;
  }
  .contact_mainwrapper .contact_form .formInline .formdiv textarea {
    width: 95.5% !important;
  }
}
@media (max-width: 581px) {
  .contact_mainwrapper .contact_form .formInline .formdiv textarea {
    width: 94.5% !important;
  }
}
@media (max-width: 481px) {
  .contact_mainwrapper .contact_wrapper .contact_bg {
    left: 78px;
  }
  .contact_mainwrapper .contactus_part h1 span {
    font-size: 40px;
  }
  .contact_mainwrapper .filloutform_part h3 {
    font-size: 23px;
  }
  .contact_mainwrapper .forminfo p {
    font-size: 14px;
  }
  .contact_mainwrapper .contact_form .formInline .formdiv textarea {
    width: 92.5% !important;
  }
}
@media (max-width: 381px) {
  .contact_mainwrapper .contact_wrapper .contact_bg {
    left: 62px;
    top: 18px;
  }
  .contact_mainwrapper .contact_form .formInline .formdiv textarea {
    width: 90.5% !important;
  }
  .contact_mainwrapper .amazon_link .amazon_link_text h3 {
    font-size: 16px;
  }
  .contact_mainwrapper .submitbtnsection .MuiButton-root {
    width: 100%;
  }
}

/* <----------------------------Contact_page Responsive End-----------------------------> */

/* --------------------------------- Contact Css End ---------------------------- */

/* --------------------------------- Start Footer CSS Start------------------------- */

.footer_section {
  background: #444;
  padding: 25px 0;
}
.footer_section .copyright_banner {
  width: 1548px;
  margin: 0 auto;
}
.footer_section .copyright_text {
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
}
.copyright_text_wrpr {
  text-align: center;
  background-color: #282626;
  padding: 20px 0;
}
.copyright_text_wrpr p {
  color: #8f8f8f;
  font-size: 14px;
}
.navsocial_wrpr {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer_section .copyright_text p {
  font-size: 16px;
  color: #7e797a;
  margin: 0 !important;
}
.footer_section .footer_socialconnect {
  cursor: pointer;
  display: flex;
}
.footer_section .footer_socialconnect img {
  padding: 0 4px;
}
.footer_section .navbar {
  position: relative;
}
.footer_section .navbar ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.footer_section .navbar li a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  padding: 7px 17px;
  display: block;
}
.footer_section .navbar li {
  position: relative;
  margin: 0 5px;
}
.footer_section .navbar li::after {
  content: "";
  position: absolute;
  height: 56%;
  width: 1px;
  background: #ddc1c7;
  background: linear-gradient(
    180deg,
    rgba(221, 193, 199, 0.3) 0%,
    rgba(221, 193, 199, 1) 50%,
    rgba(221, 193, 199, 0.3) 100%
  );
  top: 50%;
  right: -5px;
  transform: translateY(-50%);
}
.footer_section .navbar li:last-child::after {
  display: none;
}
.footer_section .navbar .active,
.footer_section .navbar li:hover {
  background-color: #e16038;
  border-radius: 5px;
}

/* .footer_section .navbar .active::after {
  content: "";
  position: absolute;
  height: 56%;
  width: 1px;
  background: #ddc1c7;
  background: linear-gradient(
    180deg,
    rgba(221, 193, 199, 0.3) 0%,
    rgba(221, 193, 199, 1) 50%,
    rgba(221, 193, 199, 0.3) 100%
  );
  top: 50%;
  left: 101%;
  transform: translateY(-50%);
  display: block;
} */

@media (max-width: 1700px) {
  .footer_section .footer_socialconnect {
    left: 0;
  }
}
@media (max-width: 1199px) {
  .navsocial_wrpr {
    flex-direction: column;
    align-items: center;
  }
  .footer_section .navbar li::after {
    display: none;
  }
  .footer_section .footer_socialconnect {
    position: relative;
    margin-top: 20px;
  }
}
@media (max-width: 991px) {
  .footer_section .copyright_text {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width: 767px) {
  .footer_section .navbar ul {
    flex-wrap: wrap;
  }
  .footer_section .navbar li {
    width: 100%;
    text-align: center;
  }
}

/* --------------------------------- Start Footer CSS End------------------------- */

.mainContent {
  background: rgb(25, 16, 33);
  background: linear-gradient(
    90deg,
    rgba(25, 16, 33, 1) 0%,
    rgba(36, 25, 41, 1) 50%,
    rgba(48, 52, 52, 1) 100%
  );
  height: 100%;
  min-height: 100vh;
}

/* --------------------------------- BlogDetails CSS Start------------------------- */
/* .blogDetails_mainwrpr .blog_mainwrapper .blog_txt_wrpr p {
  font-size: 18px;
  line-height: 25px;
  color: #fff;
} */

.blogDetails_mainwrpr .blog_mainwrapper .blog_txt_wrpr p span {
  -webkit-line-clamp: unset !important;
}

.para_wrpr {
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical !important;
  overflow: hidden;
}

/* --------------------------------- BlogDetails CSS End------------------------- */

.forget_mainwrapper .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid transparent !important;
}

.footer_mainwrpr .scrollTop {
  position: fixed;
  bottom: 15px;
  right: 15px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  color: #000000;
  font-size: 45px;
  background: #ffffff;
  border-radius: 10px;
  opacity: 0.5;
}

.scrollTop:hover {
  opacity: 1;
}

.footer_mainwrpr {
  position: relative;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
.forget_mainwrapper .MuiOutlinedInput-notchedOutline {
  border: 1px solid transparent !important;
}

.searchForm .MuiFormControl-root {
  margin: 0 10px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 162px !important;
}
.searchemail_wrpr {
  margin: 0 10px;
}
.status_wrpr {
  margin: 0 10px;
  width: 25%;
}
.date_define_wrpr {
  width: 35%;
}
.searchForm .MuiTextField-root {
  box-shadow: inset 0 0 5px #a8a5a5;
}
/* .date_define_wrpr .MuiTextField-root{box-shadow: inset 0 0 10px #8c8a8a;} */

.searchForm .searchemail_wrpr .MuiFormControl-root {
  box-shadow: inset 0 0 5px #a8a5a5;
}

.search_title_wrpr {
  width: 25%;
}
.searchemail_wrpr {
  width: 25%;
}
.date_wrpr {
  width: 35%;
}
.searchemail_wrpr {
  width: 25%;
}


/* .imagethum{ height: auto!important; overflow: inherit!important; display: flex!important; flex-wrap: wrap; justify-content: space-between;}
.imagethum img{ width: 25%!important;} */

.image-gallery button{ display: none!important;}

.image-gallery-thumbnails-wrapper nav button{ display: block!important;}

.image-gallery-slides{ position: relative; 
  height: 500px;}
.image-gallery-slide {
  position: absolute;
  overflow: hidden;
  height: 500px;
  width: 100%;
}

.image-gallery-thumbnails-container{ display: flex; flex-wrap: wrap; justify-content: center; margin-top: 10px;}

.blog_mainwrapper .blog_subwrapper button.image-gallery-thumbnail {
  border: solid 1px #fff!important;
  background: none!important;
  padding: 0!important;
  width: 60px!important;
  height: 60px!important; overflow: hidden;     margin: 5px!important; opacity: 0.5; 
}

.blog_mainwrapper .blog_subwrapper button.active{ opacity: inherit;}

.blog_mainwrapper .blog_subwrapper button.image-gallery-thumbnail img{object-fit: cover;}

 
.blog_image_block .control-dots{
  display: none;
}
.blog_image_block .carousel .thumbs{
  display: flex;
  justify-content: center;
}
.blog_image_block .carousel .thumbs-wrapper {
  margin: 0px 20px;
}
.blog_image_block .thumbs {
padding-inline-start:0
}
.blog_image_block .carousel .control-next.control-arrow{
  display: none;
}


@media (max-width: 1199px) {
  .searchForm .search_title_wrpr{margin: 0 10px 10px;}
  .searchForm .status_wrpr{margin: 0 10px 10px;}
  .searchForm .date_define_wrpr {
     margin: 0 10px 10px;
}
.searchForm .date_wrpr{margin: 0 10px 10px;}

}

@media (max-width: 991px) {
  .searchForm .search_title_wrpr{width: 65%;}
  .searchForm .status_wrpr{width: 45%;}
  .searchForm .date_define_wrpr {
    width: 45%;
}
.searchForm .date_wrpr{width: 45%;}
.searchForm .searchemail_wrpr {
  width: 45%;
}
}
@media (max-width: 600px) {
  .searchForm .search_title_wrpr{width: 100%;margin: 0 0 10px;}
  .searchForm .status_wrpr{width: 100%;margin: 0 0 10px;}
  .searchForm .date_define_wrpr {
    width: 100%;margin: 0 0 10px;
}
.searchForm .date_wrpr{width: 100%;margin: 0 0 10px;}
.searchForm .searchemail_wrpr {
  width: 100%;margin: 0 0 10px;
}
}
/* @media (max-width: 1199px) {

.cropper-container img{height: 421.43px !important;}
.upload_data_wrpr .image_container .content_wrpr div img{
      height: 305px !important;
}

} */





/* <<<<<<<<<<<-------------------------contact & blog listing modal css issue solved--------------->>>>>>>>>>>>> */
.css-1q7lp8d{
  min-width: inherit !important;
}
.css-1hw9j7s{
  background: #e0bbaf!important;
  background: linear-gradient(180deg,rgba(224,187,175,1) 0%,rgba(213,170,156,1) 50%,rgba(206,155,139,1) 50%,rgba(192,135,117,1) 100%)!important;
  background-color: none !important;
}